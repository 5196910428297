import * as types from "./actionTypes";

const initialState = {
  data: {},
  isDocumentsLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DOCUMENTS_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isDocumentsLoaded: true,
      };

    case types.DOCUMENTS_RESET:
      return initialState;

    default:
      return state;
  }
};
