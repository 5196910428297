import * as types from "./actionTypes";

const initialState = {
  data: {
    title: "",
    url: "",
    group_id: "",
    tracker: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    is_automated: false,
    is_relevant: true,
    created_at: null,
    library: {},
    departments: [],
    platform: {},
  },
  isChannel: false,
  isUpdated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANNEL_IS:
      return {
        ...state,
        isChannel: action.payload,
      };
    case types.CHANNEL_SET:
      return {
        ...state,
        data: action.payload,
      };
    case types.CHANNEL_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
      };
    case types.CHANNEL_RESET:
      return initialState;

    default:
      return state;
  }
};
