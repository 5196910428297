import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logos from "../../../components/Logos";
import Icon, { icons } from "../../../components/Icon";
import Section from "../../../components/Section";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import objectToUrlConstructor from "../../../helpers/objectToUrlConstructor";
import { connect } from "react-redux";
import { resetFilter } from "../../../reducers/filters/actions";
import Separator from "../../../components/Separator";

const initSelected = {
  platforms: [],
  names: [],
  status: [],
  libraries: [],
  departments: [],
};

const Filter = ({ isFiltersOpen, data, parsed, name, filter, resetFilter }) => {
  const rootHeader = document.querySelector("#root header");
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const [modalPaddingLeft, setModalPaddingLeft] = useState("");
  const [selected, setSelected] = useState(initSelected);
  const [byLetter, setByLetter] = useState([]);

  const handleClose = () => {
    resetFilter();
    setByLetter([]);
  };

  const handleChangeFilter = () => {
    const temp = { ...params };

    temp[name] = selected[name];

    let url = objectToUrlConstructor(temp);
    const isUrl = !!url;

    url = isUrl ? `${pathname}?${url}` : `${pathname}`;

    history.replace(url);
    handleClose();
  };

  const handleSelectFilter = (item) => {
    setSelected({
      ...selected,
      [name]: selected[name].includes(item)
        ? selected[name].filter((f) => f !== item)
        : [...selected[name], item],
    });
  };

  useEffect(() => {
    if (isFiltersOpen && !!params[name] && params[name] !== selected[name]) {
      setSelected({ ...selected, [name]: params[name].split(",") });
    }

    return () => setSelected(initSelected);
  }, [isFiltersOpen]);

  useEffect(() => {
    if (rootHeader && !modalPaddingLeft) {
      const styles = window.getComputedStyle(rootHeader);
      setModalPaddingLeft(styles.marginLeft);
    }
  }, [rootHeader, modalPaddingLeft]);

  useEffect(() => {
    if (!isFiltersOpen && !!byLetter.length) setByLetter([]);
  }, [isFiltersOpen, byLetter]);

  return (
    <MenuContainer
      isFiltersOpen={isFiltersOpen}
      modalPaddingLeft={modalPaddingLeft}
    >
      <ContainerHeader>
        <Logos />
        <HeaderCloseIcon onClick={handleClose}>
          <Icon icon={icons.close_x} />
        </HeaderCloseIcon>
      </ContainerHeader>
      <FilterName>{filter}</FilterName>
      <ContainerMain>
        {name === "names" && (
          <>
            <SortNamesByLetter>
              {data[name]
                .map((string) => string[0])
                .filter((val, i, arr) => !!val && arr.indexOf(val) === i)
                .sort()
                .map((letter, i) => {
                  const isActiveLetter = byLetter.includes(letter);
                  const letterClassName = isActiveLetter
                    ? "active-letter"
                    : null;

                  return (
                    <span
                      className={letterClassName}
                      key={i}
                      onClick={() => {
                        if (isActiveLetter) {
                          const temp = byLetter.filter((val) => val !== letter);
                          setByLetter(temp);
                        } else setByLetter([...byLetter, letter]);
                      }}
                    >
                      {letter}
                    </span>
                  );
                })}
            </SortNamesByLetter>
            <SortNamesByLetterSeparator />
          </>
        )}
        {name && (
          <ContainerMainButtons>
            {data[name]
              .filter((item) =>
                name === "names" && !!byLetter.length
                  ? byLetter.includes(item.charAt(0))
                  : item
              )
              .filter((item) =>
                name === "departments" && !!parsed.library_id
                  ? parsed.library_id.includes(item.library_id)
                  : item
              )
              .map((item, i) => {
                let text, id;
                const el = item;

                if (name === "names" || name === "status") {
                  text = el;
                }

                if (name === "names") {
                  id = el;
                } else if (name === "status") {
                  id = i;
                } else {
                  text = el.name;
                  id = el.id;
                }

                const isActive = selected[name].includes(id);

                return (
                  <ModalBtn
                    as="button"
                    key={i}
                    btnColor={isActive ? "#EDEEE9" : "#9E9D9D"}
                    btnBackgroundColor={isActive ? "#24627A" : "initial"}
                    onClick={() => handleSelectFilter(id)}
                  >
                    {text}
                  </ModalBtn>
                );
              })}
          </ContainerMainButtons>
        )}
        <ModalSubmitBtn as="button" onClick={handleChangeFilter}>
          Применить
        </ModalSubmitBtn>
      </ContainerMain>
    </MenuContainer>
  );
};

const mapStateToProps = ({
  filters: { data, name, filter, isFiltersOpen, parsed },
}) => ({
  name,
  data,
  filter,
  isFiltersOpen,
  parsed,
});

export default connect(mapStateToProps, { resetFilter })(Filter);

const SortNamesByLetter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 28px);

  gap: 1px;
  align-items: center;
  justify-items: center;
  position: relative;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 28px;
    cursor: pointer;
    font-family: Rubik, sans-serif;
    font-style: normal;
    color: #9e9d9d;
    font-size: 20px;

    &.active-letter {
      font-weight: 500;
      background-color: #24627a;
      color: #edeee9;
    }
  }

  @media (hover: hover) {
    > span:not(.active-letter):hover {
      font-weight: 600;
      background-color: #24627a;
      color: #edeee9;
    }
  }

  @media (max-width: 700px) {
    margin-bottom: 25px;
  }
`;

const SortNamesByLetterSeparator = styled(Separator)`
  margin: 20px 0;

  @media (max-width: 700px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  display: block;

  background: #ffffff;

  position: absolute;
  top: 0;
  bottom: 50px;
  right: 0;
  left: 0;
  z-index: 12000;

  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background: inherit;
  }

  transform: translateX(
    ${({ isFiltersOpen }) => (isFiltersOpen ? "0" : "-100%")}
  );
  transition: transform linear 0.2s;

  @media (min-width: 569px) {
    min-height: 100vh;
    width: 50%;
    border-right: 3px solid #24627a;
    padding-left: ${({ modalPaddingLeft }) => modalPaddingLeft};
  }

  @media (max-width: 568px) {
    width: 100%;
    border-top: 3px solid #24627a;
  }
`;

const ContainerMain = styled(Section)`
  margin-top: 25px;
  margin-bottom: 100px;

  @media (min-width: 569px) {
    height: auto;
  }
`;

const ContainerMainButtons = styled.div`
  @media (min-width: 569px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const FilterName = styled(Section)`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: #24627a;

  @media (min-width: 569px) {
    margin-top: 87px;
  }

  @media (max-width: 569px) {
    margin-top: 25px;
  }
`;

const ContainerHeader = styled.div`
  @media (min-width: 769px) {
    padding-left: 30px;
    height: 91px;
  }

  @media (max-width: 768px) {
    padding-left: 15px;
    height: 60px;
  }

  @media (min-width: 569px) {
    display: flex;
    position: sticky;
    background: #ffffff;

    top: 0;
    z-index: 1111;
  }

  @media (max-width: 568px) {
    display: none;
  }
`;

const HeaderCloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;

  svg {
    path {
      fill: #222222;
    }
  }

  @media (min-width: 769px) {
    svg {
      width: 30px;
      height: 30px;
      margin-right: 40px;
    }
  }

  @media (max-width: 768px) {
    svg {
      width: 22px;
      height: 22px;
      margin-right: 15px;
    }
  }
`;

const CardButton = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
`;

const ModalBtn = styled(CardButton)`
  border: 1px solid #9e9d9d;
  color: ${({ btnColor }) => btnColor};
  background-color: ${({ btnBackgroundColor }) => btnBackgroundColor};
  padding: 4px 10px;
  margin-bottom: 10px;
  border-radius: 20px;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: 569px) {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  @media (max-width: 568px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    justify-content: center;

    :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: #24627a;
      border-color: #24627a;
      color: #edeee9;
    }
  }
`;

const ModalSubmitBtn = styled(CardButton)`
  justify-content: center;
  align-items: center;
  color: #edeee9;
  background-color: #24627a;
  height: 50px;
  width: 178px;
  border-radius: 35px;

  font-size: 18px;
  line-height: 21px;

  @media (min-width: 569px) {
    margin-top: 30px;
  }

  @media (max-width: 568px) {
    height: 45px;
    width: 100%;
    margin-top: 25px;
  }
`;
