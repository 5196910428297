import React from "react";

export default () => (
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.61538 11L-7.21238e-08 9.35L3.76923 5.5L-4.08701e-07 1.65L1.61538 -7.06107e-08L7 5.5L1.61538 11Z"
      fill="#24627A"
    />
  </svg>
);
