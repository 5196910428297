import React from "react";
import styled from "styled-components";
import Section from "../../../components/Section";
import { setFilters } from "../../../reducers/filters/actions";
import { connect } from "react-redux";

const Filters = ({ setFilters, view }) => {
  const filterNames = {
    platforms: "Платформа",
    names: "ФИО",
    status: "Статус автоматизации",
    libraries: "Юридическое лицо",
    departments: "Подразделение",
  };

  return (
    <FiltersContainer view={view}>
      <FiltersSection view={view}>
        <div>
          {Object.keys(filterNames).map((item, i) => {
            return (
              <FilterBtn
                key={i}
                onClick={() =>
                  setFilters({
                    name: item,
                    filter: filterNames[item],
                    isFiltersOpen: true,
                  })
                }
              >{`+ ${filterNames[item]}`}</FilterBtn>
            );
          })}
        </div>
      </FiltersSection>
    </FiltersContainer>
  );
};

export default connect(null, { setFilters })(Filters);

const FiltersSection = styled(Section)`
  margin-top: 40px;

  @media (min-width: 431px) {
    padding-bottom: 25px;
  }

  @media (max-width: 430px) {
    padding: 0 0 25px 0;
  }

  > div {
    display: flex;
    align-items: center;

    overflow: auto;
    scroll-behavior: smooth;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1151px) {
      column-gap: 20px;
    }

    @media (max-width: 1150px) {
      column-gap: 10px;
    }
  }

  @media (min-width: 431px) {
    ::before {
      display: block;
      content: "";
      height: 3px;
      width: 100%;
      background: ${({ view }) => (view === 2 ? "#24627a" : "initial")};
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
`;

const FiltersContainer = styled.div`
  @media (min-width: 431px) {
    background: ${({ view }) => (view === 1 ? "#ffffff" : "initial")};
  }
`;

const CardButton = styled.button`
  display: flex;
  height: fit-content;
  width: fit-content;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
`;

const FilterBtn = styled(CardButton)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  background-color: initial;

  @media (min-width: 1151px) {
    border: none;
    padding-right: 0;
    padding-left: 0;
    color: #24627a;
  }

  @media (max-width: 1150px) {
    border: 1px solid #9e9d9d;
    border-radius: 20px;
    padding: 6px 15px;
    color: #9e9d9d;
  }

  @media (max-width: 700px) {
    :first-child {
      margin-left: 15px;
    }

    :not(:last-child) {
      margin-right: 5px;
    }
  }
`;
