import React from "react";
import styled from "styled-components";
import LogoGrid from "./LogoGrid";
import Icon, { icons } from "../Icon";

const InfoLeft = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    margin-bottom: 20px;
  }

  span {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;

    color: #24627a;
  }
`;

const InfoLeftSocial = styled.div`
  display: block;

  a {
    :not(:last-child) {
      margin-right: 10px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const InfoLeftHeader = styled.div`
  display: flex;

  @media (min-width: 991px) {
    flex-direction: column;

    > :last-child {
      margin-top: 20px;
    }
  }

  @media (max-width: 990px) and (min-width: 569px) {
    > :last-child {
      min-width: calc((30px * 4) + 30px);
      margin-left: 20px;
    }
  }

  @media (max-width: 568px) {
    flex-direction: column;

    > :last-child {
      margin-top: 20px;
    }
  }
`;

export default ({ className }) => {
  return (
    <InfoLeft className={className}>
      <InfoLeftHeader>
        <span>
          Государственное бюджетное учреждение культуры города Москвы
          «Центральная универсальная научная библиотека имени
          Н.А.&nbsp;Некрасова»
        </span>
        <InfoLeftSocial>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vk.com/nekrasovkalibrary"
            aria-label="Страничка Некрасовки в ВКонтакте"
          >
            <Icon icon={icons.vk} fill="#9E9D9D" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/c/nekrasovkalibrary"
            aria-label="YouTube канал Некрасовки"
          >
            <Icon icon={icons.youtube} fill="#9E9D9D" />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/nekrasovkalibrary"
            aria-label="Страничка Некрасовки в Телеграмм"
          >
            <Icon icon={icons.telegram} fill="#9E9D9D" />
          </a>
        </InfoLeftSocial>
      </InfoLeftHeader>
      <LogoGrid />
    </InfoLeft>
  );
};
