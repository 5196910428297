import styled from "styled-components";

export default styled.div`
  display: flex;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
`;
