import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import * as XLSX from "xlsx";

import Icon, { icons } from "../../components/Icon";
import Section from "../../components/Section";
import Card from "./Card";
import Button from "../../components/Button";
import Modal from "../Modal";
import Filter from "./Filter";
import Filters from "./Filters";
import Selected from "./Selected";

import {
  getChannels,
  resetChannels,
  setChannels,
} from "../../reducers/channels/actions";
import { resetChannel, setIsChannel } from "../../reducers/channel/actions";
import {
  getFilters,
  resetFilter,
  setPagesFilters,
  setParsedFilters,
} from "../../reducers/filters/actions";

import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import qs from "qs";
import PagesLoadMore from "../../components/PagesLoadMore";
import axios from "axios";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import Row from "./Row";
import Separator from "../../components/Separator";

const abr = {
  IDS: "№",
  TIT: "Название канала",
  LIB: "Юридическое лицо",
  DEP: "Подразделение",
  URL: "Ссылка",
  REL: "Актуальность",
  PLA: "Платформа",
  GID: "ID Группы/Счётчика",
  NAM: "ФИО",
  EMA: "Почта",
  PHO: "Телефон",
  STA: "Статус автоматизации",
  CAA: "Проверка автоматизации администратором",
};

const MediaChannels = ({
  getChannels,
  setIsChannel,
  resetChannels,
  resetChannel,
  getFilters,
  resetFilter,
  setParsedFilters,
  setPagesFilters,
  filters,
  channels,
  channel,
  tokenParsed,
  onExit,
  checkTokenExpiredYesUpdate,
  errors,
}) => {
  const { search } = useLocation();
  const { dispatch } = useContext(AlertContext);
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const { width } = useWindowDimensions();
  const [isTableScrolling, setTableScrolling] = useState(false);
  const [view, setView] = useState(width > 769 ? 1 : 2);

  const handleOpen = () => {
    resetChannel();
    setIsChannel(true);
  };

  const exportXLSX = async () => {
    const promptValue = "Введите имя файла без расширения (необязательно)";
    const url = `${process.env.REACT_APP_CHANNELS_API}/channel`;
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const params = { filters: filters.parsed, export: "export" };

    const config = {
      headers: {
        Authorization: `Bearer ${TOKEN_KEY}`,
        "Accept-Language": "ru",
        "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
        "Content-Type": `application/json`,
      },
      method: "get",
      params,
    };

    const temp = await axios.get(url, config);

    const json = temp.data.data.map((ch, i) => {
      return {
        [abr["IDS"]]: i + 1,
        [abr["TIT"]]: ch.title,
        [abr["LIB"]]: ch.library.name,
        [abr["DEP"]]: ch.departments.map((m) => m.name).join(", "),
        [abr["URL"]]: ch.url,
        [abr["EMA"]]: ch.contact_email,
        [abr["PHO"]]: ch.contact_phone,
        [abr["NAM"]]: ch.contact_name,
        [abr["PLA"]]: ch.platform.name,
        [abr["GID"]]: ch.group_id || ch.tracker,
        [abr["REL"]]: filters.data.relevancy[ch.is_relevant ? 1 : 0],
        [abr["STA"]]: filters.data.status[ch.is_automated ? 1 : 0],
        [abr["CAA"]]: filters.data.automation_check[ch.automation_check],
      };
    });

    const fileName = prompt(promptValue, "") || "SheetJSExportAOO";

    const ws = XLSX.utils.json_to_sheet(json);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const setParamsToFilters = () => {
    const parsed = {};

    Object.keys(params).forEach((name) => {
      const isIncludesName = [
        "title",
        "group_id",
        "tracker",
        "contact_name",
        "contact_email",
        "url",
      ].includes(name);

      if (name === "status") {
        parsed["is_automated"] = params[name].split(",");
      }

      if (name === "libraries") {
        parsed["library_id"] = params[name].split(",");
      }

      if (name === "departments") {
        parsed["departments"] = params[name].split(",");
      }

      if (name === "platforms") {
        parsed["platform_id"] = params[name].split(",");
      }

      if (name === "names") {
        parsed["contact_name"] = params[name].split(",");
      }

      if (isIncludesName) {
        parsed[name] = params[name].split(",");
      }
    });

    setParsedFilters(parsed);
  };

  const fetchCardsByPage = () => {
    setPagesFilters({ ...filters.pages, page: filters.pages.next });
    getChannels();
  };

  const useKeyPress = (e) => {
    if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
      e.preventDefault();
      document.getElementById("media_channels_search").focus();
    }
  };

  useEffect(() => {
    if (!filters.loading) getFilters();
  }, [filters.loading]);

  useEffect(() => {
    setParamsToFilters();
    resetChannels();
    getChannels();
  }, [search]);

  useEffect(() => {
    if (channel.isUpdated) {
      if (!!channel.data.id) {
        const temp = channels.data.map((item) => {
          if (item.id === channel.data.id) {
            return {
              ...channel.data,
              departments: channel.data.departments_copy,
            };
          } else return item;
        });

        setChannels(temp);
      } else {
        resetChannel();
        resetChannels();
        getChannels();
      }
    }
  }, [channel.isUpdated]);

  useEffect(() => {
    if (errors.isError) {
      dispatch({
        type: "ALERT_ON",
        text: errors.message.short,
        name: "warning",
      });
    }
  }, [errors.isError]);

  useEffect(() => {
    if (!!channel.data.id && channel.isChannel) setIsChannel(false);
  }, [channel.data.id]);

  useEffect(() => {
    window.addEventListener("keydown", useKeyPress);

    return () => window.removeEventListener("keydown", useKeyPress);
  }, []);

  const departmentsLabel = `
              <span>Обособленное структурное подразделение</span>
              <span>указан в соответствии с Уставом учреждения</span>`;

  const nameLabel = `
              <span>ФИО</span>
              <span>контактное лицо</span>`;

  const onScroll = (e) => {
    if (!!e.target.children[0]) {
      const { x } = e.target.children[0].getBoundingClientRect();
      setTableScrolling(x < 0);
    }
  };

  const handleView = (num) => {
    setView(num);
  };

  return (
    <>
      <HeaderSection isChannel={channel.isChannel}>
        {channel.isChannel ? (
          <NewCard
            data={{
              ...channel.data,
              library: !!tokenParsed.attributes.is_admin
                ? channel.data.library
                : {
                    id: tokenParsed.attributes.library_id,
                    name: filters.data["libraries"].find(
                      (f) => f.id === tokenParsed.attributes.library_id,
                    ).name,
                  },
            }}
            isActive={channel.isChannel}
            isAdmin={!!tokenParsed.attributes.is_admin}
          />
        ) : (
          <>
            <AddNewCardButton
              btn_txt="Добавить канал"
              secondary={true}
              onBtnClick={handleOpen}
            />
            <CardsAction>
              <Button
                icon={icons.xlsx}
                icon_fill="rgb(36, 98, 122)"
                onBtnClick={exportXLSX}
              />
              <Button
                icon={icons.exit}
                icon_fill="rgb(36, 98, 122)"
                onBtnClick={onExit}
              />
            </CardsAction>
          </>
        )}
      </HeaderSection>
      <Filters view={view} />
      {filters.loading ? (
        <>
          <Modal
            isFiltersOpen={filters.isFiltersOpen}
            filterClose={resetFilter}
          >
            <Filter />
          </Modal>
          <ChannelsSelected view={view} />
        </>
      ) : null}
      {width > 769 && (
        <View view={view}>
          <ViewSection>
            <div onClick={() => handleView(1)}>
              <Icon
                icon={icons.filtersList}
                fill={view === 1 ? "rgb(36, 98, 122)" : "#9E9D9D"}
                height={20}
                width={20}
              />
            </div>
            <div onClick={() => handleView(2)}>
              <Icon
                icon={icons.filtersMozaic}
                fill={view === 2 ? "rgb(36, 98, 122)" : "#9E9D9D"}
                height={20}
                width={20}
              />
            </div>
          </ViewSection>
        </View>
      )}
      {channels.loading && filters.loading ? (
        <>
          {view === 1 && (
            <>
              <TableContainer onScroll={onScroll}>
                <Table isTableScrolling={isTableScrolling}>
                  <thead>
                    <tr>
                      {Object.keys(abr).map((item, i) => {
                        if (item === "DEP") {
                          return (
                            <THDepartment key={i}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: departmentsLabel,
                                }}
                              />
                            </THDepartment>
                          );
                        } else if (item === "NAM") {
                          return (
                            <THDepartment key={i}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: nameLabel,
                                }}
                              />
                            </THDepartment>
                          );
                        } else if (item === "CAA") {
                          return (
                            !!tokenParsed.attributes.is_admin && (
                              <th style={{ minWidth: "250px" }} key={i}>
                                {abr[item]}
                              </th>
                            )
                          );
                        } else return <th key={i}>{abr[item]}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {channels.data.map((item, i) => {
                      return (
                        <Row
                          key={i}
                          index={i + 1}
                          data={item}
                          isAdmin={!!tokenParsed.attributes.is_admin}
                          isTableScrolling={isTableScrolling}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
              {!!filters.pages.next ? (
                <CardsPagesLoadMore onBtnClick={fetchCardsByPage} />
              ) : null}
            </>
          )}
          {view === 2 && (
            <>
              <Cards>
                {channels.data.map((item, i) => {
                  const isActive = channel.data.id === item.id;

                  return (
                    <Card
                      key={i}
                      data={item}
                      isActive={isActive}
                      isAdmin={!!tokenParsed.attributes.is_admin}
                    />
                  );
                })}
              </Cards>
              {!!filters.pages.next ? (
                <CardsPagesLoadMore onBtnClick={fetchCardsByPage} />
              ) : null}
              <Separator />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = ({
  channels,
  channel,
  filters,
  allowance: { tokenParsed, onExit, checkTokenExpiredYesUpdate },
  errors,
}) => ({
  channels,
  channel,
  filters,
  tokenParsed,
  onExit,
  checkTokenExpiredYesUpdate,
  errors,
});
export default connect(mapStateToProps, {
  getChannels,
  resetChannels,
  setChannels,
  setIsChannel,
  resetChannel,
  getFilters,
  resetFilter,
  setParsedFilters,
  setPagesFilters,
})(MediaChannels);

const ChannelsSelected = styled(Selected)`
  background: ${({ view }) => (view === 1 ? "#ffffff" : "initial")};
`;

const TableContainer = styled.div`
  max-width: 100vw;
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(36, 98, 122);
  }
`;

const Table = styled.table`
  background: #ffffff;
  font-family: Rubik, sans-serif;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 10px;
    border: 1px #89949d solid;

    :nth-child(2) {
      position: sticky;
      left: 0;
      z-index: 2;
      box-shadow: ${({ isTableScrolling }) =>
        isTableScrolling ? "9px 0 5px -5px #89949d" : "none"};
    }
  }

  td {
    vertical-align: top;
  }

  tbody {
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 111;

    th {
      font-size: 16px;
      font-weight: 500;
      color: #24627a;
      background-color: #ffffff;

      :nth-child(2) {
        min-width: 420px;
      }
    }
  }
`;

const THDepartment = styled.th`
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    > span {
      :last-child {
        font-size: 11px !important;
        font-weight: 400;
        white-space: initial;
      }
    }
  }
`;

const Cards = styled(Section)`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  > button {
    @media (hover: hover) and (pointer: fine) {
      :hover {
        background: rgb(36, 98, 122);
        color: rgb(237, 238, 233);
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 35px;
  }

  @media (max-width: 700px) {
    padding-left: 0;
  }
`;

const CardsPagesLoadMore = styled(PagesLoadMore)`
  @media (min-width: 431px) {
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0;
    max-width: 100%;
  }

  @media (max-width: 430px) {
    > button {
      width: 100%;
    }
  }
`;

const AddNewCardButton = styled(Button)`
  @media (hover: hover) {
    :hover {
      background: rgb(36, 98, 122);
      color: rgb(237, 238, 233);
    }
  }
`;

const CardsAction = styled.div`
  display: flex;
  column-gap: 20px;
  height: 20px;

  svg,
  button {
    height: 100%;
    margin: 0;
  }

  button {
    border: none;
    padding: 0;
  }

  @media (hover: hover) {
    svg:hover {
      fill: #222222;
    }
  }

  @media (max-width: 700px) {
    button,
    svg {
      margin: 0;
    }

    button {
      font-size: 0;
    }
  }
`;

const View = styled.div`
  display: flex;
  padding: 0 30px 25px 30px;

  @media (hover: hover) {
    svg:hover {
      fill: rgb(36, 98, 122);
    }
  }

  background: ${({ view }) => (view === 1 ? "#ffffff" : "initial")};
`;

const ViewSection = styled(Section)`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  width: 100%;

  > div {
    cursor: pointer;
  }
`;

const NewCard = styled(Card)`
  ::after,
  > svg {
    display: none;
  }
`;

const HeaderSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Rubik, sans-serif;
  font-style: normal;

  margin-top: 30px;

  @media (max-width: 700px) {
    flex-direction: row-reverse;
    ${({ isChannel }) => isChannel && "padding-left: 0"};
  }
`;
