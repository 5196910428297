import React, { Fragment } from "react";
import styled from "styled-components";

import Section from "../Section";
import Separator from "../Separator";
import Logos from "../Logos";
import Icon, { icons } from "../Icon";
import { connect } from "react-redux";
import { setMenu } from "../../reducers/menu/actions";
import { resetFilter } from "../../reducers/filters/actions";

const Header = ({ isMenuOpen, isFiltersOpen, setMenu, resetFilter }) => {
  const onMenuIconClick = () => {
    isFiltersOpen ? resetFilter() : setMenu();
  };

  return (
    <Fragment>
      <HeaderContainer isModalOpen={isMenuOpen || isFiltersOpen}>
        <Logos />
        <HeaderRoutes>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://nekrasovka.ru/articles/guest"
          >
            Посетителям
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://pro.nekrasovka.ru"
          >
            Коллегам
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://nekrasovka.ru/articles/library"
          >
            О&nbsp;библиотеке
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://biblioteka.nekrasovka.ru/addresses"
          >
            Адреса
          </a>
        </HeaderRoutes>
        <HeaderSocial>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCqbg0K1moj7OXP7p3GqyEgQ"
            aria-label="YouTube канал Некрасовки"
          >
            <Icon icon={icons.youtube} fill="#9E9D9D" />
          </a>
        </HeaderSocial>
        <HeaderMenuMobile onClick={onMenuIconClick}>
          <Icon
            icon={isMenuOpen || isFiltersOpen ? icons.close_x : icons.menu}
            height="26"
            width={isMenuOpen ? "26" : "40"}
            fill="#222222"
          />
        </HeaderMenuMobile>
      </HeaderContainer>
      <HeaderSeparator />
    </Fragment>
  );
};

const mapStateToProps = ({
  menu: { isMenuOpen },
  filters: { isFiltersOpen },
}) => ({
  isMenuOpen,
  isFiltersOpen,
});

export default connect(mapStateToProps, { setMenu, resetFilter })(Header);

const HeaderContainer = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: 769px) {
    height: 90px;
  }

  @media (max-width: 768px) {
    height: 60px;
  }

  @media (max-width: 568px) {
    ${(props) =>
      props.isModalOpen &&
      `
    background: #edeee9;
    position: fixed;
    width: 100%;
    z-index: 111111;
    `};
  }
`;

const HeaderSeparator = styled(Separator)`
  margin-top: 0;
`;

const HeaderRoutes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;

  a {
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #222220;
    text-decoration: none;
  }

  @media (min-width: 769px) {
    a:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    a:not(:last-child) {
      margin-right: 10px;
    }

    margin-left: 10px;
  }

  @media (max-width: 568px) {
    display: none;
  }
`;

const HeaderMenuMobile = styled.div`
  @media (min-width: 569px) {
    display: none;
  }

  @media (max-width: 568px) {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

const HeaderSocial = styled.div`
  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    display: none;
  }

  a {
    :not(:last-child) {
      margin-right: 10px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
