import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  fetchDocuments,
  resetDocuments,
} from "../../reducers/documents/actions";
import Pages from "../../components/Pages";
import OpenPage from "../../components/OpenPage";
import { data } from "../../data";

class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: data.filter((a) => a.id === props.match.params.document),
    };

    props.fetchDocuments(
      this.state.data[0].theme.toString(),
      this.state.data[0].id
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.document !== this.props.match.params.document) {
      this.setState({
        data: data.filter((a) => a.id === this.props.match.params.document),
      });
    }
  }

  componentWillUnmount() {
    this.props.resetDocuments();
  }

  render() {
    const { documents } = this.props;

    return (
      <Fragment>
        <OpenPage data={this.state.data[0]} />
        {documents.isDocumentsLoaded && <Pages data={documents.data} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ documents, page }) => ({
  page,
  documents,
});

export default connect(mapStateToProps, {
  fetchDocuments,
  resetDocuments,
})(Container);
