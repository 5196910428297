import React from "react";

export default () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="-1.5"
      x2="41"
      y2="-1.5"
      transform="matrix(2.17983e-05 -1 1 2.40326e-05 3.00024 42)"
      stroke="#24627A"
      strokeWidth="3"
    />
    <line
      x1="3.00024"
      y1="40.5"
      x2="41.0002"
      y2="40.5"
      stroke="#24627A"
      strokeWidth="3"
    />
    <path
      d="M1.94004 2.06417L39.9448 40.0689"
      stroke="#24627A"
      strokeWidth="3"
    />
  </svg>
);
