import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import styled from "styled-components";

import { themes, types } from "../../data";
import objectToUrlConstructor from "../../helpers/objectToUrlConstructor";
import Button from "../Button";
import Section from "../Section";
import { icons } from "../Icon";

export default () => {
  const [selected, setSelected] = useState({});
  const { search } = useLocation();
  const history = useHistory();
  const { theme, type, part, year, query } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const removePropertiesByKey = (btn, m) => {
    const params = {
      type,
      theme,
      part,
      query,
      year,
    };

    params[btn] = params[btn].split(",").filter((f) => f !== m);
    params[btn] = !!params[btn].length ? params[btn] : undefined;

    let url = objectToUrlConstructor(params);
    const isUrl = !!url;
    url = isUrl ? `/search?${url}` : "";

    history.replace(url);

    if (selected[btn]) delete selected[btn][m];
  };

  const setObject = (obj) => {
    let temp = {};
    const arr = {
      theme: themes,
      type: types,
    };

    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        obj[key].split(",").forEach((m) => {
          temp = {
            ...temp,
            [key]: { ...temp[key], [m]: key === "year" ? m : arr[key][m] },
          };
        });
      }
    });

    return { data: { ...temp } };
  };

  useEffect(() => {
    const object = {
      theme,
      type,
      year,
    };
    const temp = setObject(object);

    setSelected(temp.data);
  }, [theme, type, year]);

  return Object.keys(selected).length ? (
    <Selected>
      {Object.keys(selected).map((btn, i) => {
        return Object.keys(selected[btn]).map((m) => {
          return (
            <Button
              key={i}
              secondary="true"
              btn_c="#9E9D9D"
              btn_bc="#9E9D9D"
              btn_bgc="#FFFFFF"
              btn_txt={selected[btn][m]}
              icon={icons.close_x}
              icon_fill="#9E9D9D"
              icon_height="10"
              icon_width="10"
              onBtnClick={() => removePropertiesByKey(btn, m)}
            />
          );
        });
      })}
    </Selected>
  ) : null;
};

const Selected = styled(Section)`
  display: flex;
  margin-top: 25px;
  overflow: auto;
  scroll-behavior: smooth;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  button {
    margin-right: 10px;
  }
`;
