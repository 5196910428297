import React from "react";

export default () => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.17483L1.2 0L4 2.74126L6.8 0L8 1.17483L4 5.09091L0 1.17483Z"
      fill="#222222"
    />
  </svg>
);
