import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import SubHeader from "../../components/SubHeader";
import NavParts from "../../components/NavParts";
import NavTitle from "../../components/NavTitle";
import NavThemes from "../../components/NavThemes";
import SearchInput from "../../components/SearchInput";
import ModalFilters from "../../components/ModalFilters";
import ModalMenu from "../../components/ModalMenu";
import Filters from "../../components/Filters";
import Selected from "../../components/Selected";
import Section from "../../components/Section";
import Routes from "../Routes";
import Modal from "../Modal";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { resetFilters } from "../../reducers/filters/actions";
import Separator from "../../components/Separator";

class Main extends Component {
  render() {
    const {
      isFiltersOpen,
      isMenuOpen,
      location: { pathname },
      resetFilters,
    } = this.props;

    const isNotChannels = !(
      pathname.includes("callback") || pathname.includes("channels")
    );

    return (
      <>
        <Container>
          <Helmet>
            <title>Некрасовка ПРО для библиотекарей</title>
            <meta
              property="og:site_name"
              content="Некрасовка ПРО для библиотекарей"
            />
            <meta
              property="og:title"
              content="Некрасовка ПРО для библиотекарей"
            />
            <meta name="title" content="Некрасовка ПРО для библиотекарей" />
            <meta
              property="og:description"
              content="Портал Центральной универсальной научной библиотеки имени Н.А. Некрасова для коллег из библиотек и других учреждений культуры."
            />
            <meta
              name="description"
              content="Портал Центральной универсальной научной библиотеки имени Н.А. Некрасова для коллег из библиотек и других учреждений культуры."
            />
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_URL}${pathname}`}
            />
            <meta
              property="og:image"
              content={`${process.env.REACT_APP_URL}/images/social_all.png`}
            />
            <link
              rel="canonical"
              href={`${process.env.REACT_APP_URL}${pathname}`}
            />
          </Helmet>
          {isNotChannels && (
            <NavPartsMd>
              <NavParts />
            </NavPartsMd>
          )}
          <SubHeader isNotChannels={isNotChannels} />
          {isNotChannels && (
            <NavPartsLg>
              <NavParts />
            </NavPartsLg>
          )}
          {isNotChannels && (
            <Section>
              <MainSearchInput placeholder="Для поиска введите тему или ключевое слово" />
            </Section>
          )}
          {isNotChannels && <Filters />}
          {isNotChannels && <Selected />}
          {isNotChannels && <NavTitle />}
          <Routes />
          {isNotChannels && <NavThemes />}
          <Modal
            isFiltersOpen={isFiltersOpen}
            isMenuOpen={isMenuOpen}
            filterClose={resetFilters}
          >
            {isNotChannels && <ModalFilters />}
            <ModalMenu />
          </Modal>
        </Container>
        {isNotChannels && <Separator />}
      </>
    );
  }
}

const mapStateToProps = ({
  filters: { isFiltersOpen },
  menu: { isMenuOpen },
}) => ({
  isFiltersOpen,
  isMenuOpen,
});

export default withRouter(connect(mapStateToProps, { resetFilters })(Main));

const Container = styled.main`
  @media (max-width: 700px) {
    overflow-x: hidden;
  }
`;

const MainSearchInput = styled(SearchInput)`
  @media (min-width: 1151px) {
    display: none !important;
  }
`;

const NavPartsLg = styled(Section)`
  @media (max-width: 1150px) {
    display: none !important;
  }
`;

const NavPartsMd = styled(Section)`
  @media (min-width: 1151px) {
    display: none !important;
  }

  @media (max-width: 1090px) {
    padding: 0;
  }
`;
