import React from "react";
import styled from "styled-components";
import Section from "../../components/Section";

const StyledGrid = styled(Section)`
  display: grid;

  @media (min-width: 769px) {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media (max-width: 768px) {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  @media (min-width: 569px) {
    margin-top: 50px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }

  @media (max-width: 568px) {
    margin-top: 40px;
    grid-template-columns: 1fr;
  }

  * {
    font-family: Rubik, sans-serif;
    font-style: normal;
  }
`;

const GridItemDefault = styled.div`
  color: #222220;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const PlacesGridItem = styled.div`
  display: block;
`;

const PlaceColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PlaceColumnName = styled.span`
  font-weight: 500;
  color: #24627a;
`;

const PlaceColumnNameTop = styled(PlaceColumnName)`
  @media (min-width: 569px) {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 568px) {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 21px;
  }
`;

const PlaceColumnNameMF = styled(PlaceColumnName)`
  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const PlacesGrid = ({ data }) => {
  const lang = 0;

  return (
    <StyledGrid>
      {data.map((place, placeIndex) => {
        const { url, address, open, phone, mail, name } = place;

        return (
          <PlacesGridItem key={placeIndex}>
            <PlaceColumn>
              <PlaceColumnNameTop
                as="a"
                href={url}
                className={url ? "" : "disabled"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {name[lang]}
              </PlaceColumnNameTop>
              <GridItemDefault
                as="a"
                href={
                  "https://yandex.ru/maps/?text=" +
                  address[1].replace(/[ ]/g, "+")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {address[lang]}
              </GridItemDefault>
            </PlaceColumn>
            <PlaceColumn>
              <PlaceColumnNameMF>
                {["Режим работы:", "Open:"][lang]}
              </PlaceColumnNameMF>
              {open.map((opened, openedIndex) => (
                <GridItemDefault as="span" key={openedIndex}>
                  {opened[lang]}
                </GridItemDefault>
              ))}
            </PlaceColumn>
            {(phone || mail) && (
              <PlaceColumn>
                <PlaceColumnNameMF>
                  {["Контакты:", "Contacts:"][lang]}
                </PlaceColumnNameMF>
                {phone && (
                  <GridItemDefault as="a" href={"tel:" + phone}>
                    {phone}
                  </GridItemDefault>
                )}
                {mail && (
                  <GridItemDefault as="a" href={"mailto:" + mail}>
                    {mail}
                  </GridItemDefault>
                )}
              </PlaceColumn>
            )}
          </PlacesGridItem>
        );
      })}
    </StyledGrid>
  );
};

export default PlacesGrid;
