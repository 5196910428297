import React, { Fragment } from "react";
import styled from "styled-components";

import Rectangle from "../Rectangle";
import PageRect from "../OpenPageRect";
import Section from "../Section";
import Breadcrumbs from "../Breadcrumbs";
import Icon, { icons } from "../Icon";
import { Helmet } from "react-helmet";

const OpenPage = ({ data }) => {
  const { title, description, actions, part, theme, type, year } = data;

  const checkTextIncludeLink = (text) => {
    const r1 = /<a\s*.*>\s*.*<\/a>/g;
    const isTagA = r1.test(text);

    return isTagA ? (
      <PageTextLink dangerouslySetInnerHTML={{ __html: text }} />
    ) : (
      text
    );
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta name="title" content={title} />
        <meta property="og:description" content={description.toString()} />
        <meta name="description" content={description.toString()} />
      </Helmet>
      <PageSection id={`document_${data.id}`}>
        <Page>
          <PageContent>
            {title && <PageTitle>{title}</PageTitle>}
            {!!description.length && (
              <PageTextContainer>
                {description.map((item, i) => (
                  <PageText key={i}>{checkTextIncludeLink(item)}</PageText>
                ))}
              </PageTextContainer>
            )}
            {!!actions.length && (
              <PageRectContainer>
                {actions.map((item, i) => (
                  <PageRect key={i} data={item} />
                ))}
              </PageRectContainer>
            )}
          </PageContent>
          <Breadcrumbs tags={[part, type, theme, year]} />
          <Icon icon={icons.triangle} />
        </Page>
      </PageSection>
    </Fragment>
  );
};

export default OpenPage;

const PageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
`;

const PageRectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (min-width: 569px) {
    margin-top: 30px;
  }

  @media (max-width: 568px) {
    margin-top: 25px;
  }
`;

const PageSection = styled(Section)`
  @media (max-width: 568px) {
    padding-left: 0;
  }
`;

const Page = styled(Rectangle)`
  display: grid;

  svg {
    position: absolute;
    top: -3px;
    right: -3px;
  }

  @media (min-width: 1219px) {
    grid-template-columns: 760px auto;
  }

  @media (max-width: 1218px) and (min-width: 769px) {
    grid-template-columns: 70% auto;
  }

  @media (min-width: 769px) {
    grid-column-gap: 30px;
    margin-top: 50px;
    padding: 30px 20px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-row-gap: 30px;
    margin-top: 35px;
    padding: 20px 15px;
  }

  @media (max-width: 568px) {
    border-left: none;
  }
`;

const PageContent = styled.div`
  width: 100%;
`;

const PageTitle = styled.h3`
  margin: 0;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;

  color: #24627a;

  @media (min-width: 769px) {
    font-size: 36px;
    line-height: 41px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-right: 40px;
  }
`;

const PageText = styled.p`
  font-family: KazimirText, sans-serif;
  font-style: normal;
  font-weight: normal;
  margin: 0;

  color: #222220;

  @media (min-width: 769px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const PageTextLink = styled.span`
  a {
    color: initial;
    text-decoration: underline;
  }
`;
