import * as types from "./actionTypes";

const initialState = {
  data: {},
  id: 0,
  isPageLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PAGE_FETCH:
      return {
        ...state,
        id: action.id,
      };

    case types.PAGE_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data[0],
        isPageLoaded: true,
      };

    case types.PAGE_RESET:
      return initialState;

    default:
      return state;
  }
};
