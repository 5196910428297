import React from "react";

import logo_nekrasovka from "./icons/logo_nekrasovka";
import logo_biblio from "./icons/logo_biblio";
import logo_department from "./icons/logo_department";
import logo_biblio_full from "./icons/logo_biblio_full";
import logo_nekrasovka_new from "./icons/logo_nekrasovka_new";
import apple_podcast from "./icons/apple_podcast";
import yandex_podcast from "./icons/yandex_podcast";
import arrow_right from "./icons/arrow_right";
import arrow_up from "./icons/arrow_up";
import arrow_down from "./icons/arrow_down";
import telegram from "./icons/telegram";
import search from "./icons/search";
import menu from "./icons/menu";
import cycle from "./icons/cycle";
import checked from "./icons/checked";
import triangle from "./icons/triangle";
import close from "./icons/close";
import close_x from "./icons/close_x";
import vk from "./icons/vk";
import youtube from "./icons/youtube";
import instagram from "./icons/instagram";
import fb from "./icons/fb";
import xlsx from "./icons/xlsx";
import exit from "./icons/exit";
import filters_list from "./icons/filters_list";
import filters_mozaic from "./icons/filters_mozaic";

export default ({ icon, ...props }) => {
  const Icon = icon;

  return <Icon {...props} />;
};

export const icons = {
  filtersList: filters_list,
  filtersMozaic: filters_mozaic,
  logoNekrasovka: logo_nekrasovka,
  logoDepartment: logo_department,
  logo_nekrasovka_new: logo_nekrasovka_new,
  logoBiblio: logo_biblio,
  logo_biblio_full: logo_biblio_full,
  apple_podcast: apple_podcast,
  arrow_right: arrow_right,
  arrow_up: arrow_up,
  arrow_down: arrow_down,
  yandex_podcast: yandex_podcast,
  telegram: telegram,
  triangle: triangle,
  search: search,
  menu: menu,
  cycle: cycle,
  close: close,
  close_x: close_x,
  checked: checked,
  vk: vk,
  youtube: youtube,
  instagram: instagram,
  fb: fb,
  xlsx: xlsx,
  exit: exit,
};
