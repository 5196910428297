import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ProgressBar from "../components/ProgressBar";
import Main from "./Main";

export default () => {
  return (
    <>
      <ProgressBar />
      <Header />
      <Main />
      <Footer />
    </>
  );
};
