import React from "react";

export default () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9474 10C18.9474 10.8683 18.8147 11.7042 18.5813 12.5H16.7719C17.0604 11.7167 17.2249 10.8775 17.2249 10C17.2249 5.865 13.748 2.5 9.47368 2.5C8.0044 2.5 6.63502 2.90333 5.46459 3.595L7.06479 5.83333H1.04124L2.89464 0L4.48708 2.2275C5.93742 1.35333 7.63837 0.833333 9.47368 0.833333C14.7049 0.833333 18.9474 4.9375 18.9474 10ZM13.475 16.41C12.3063 17.0983 10.9395 17.5 9.47368 17.5C5.20019 17.5 1.72249 14.135 1.72249 10C1.72249 9.1225 1.88699 8.28333 2.17464 7.5H0.366029C0.132632 8.29583 0 9.13167 0 10C0 15.0625 4.24163 19.1667 9.47368 19.1667C11.3056 19.1667 13.0039 18.6492 14.4517 17.7775L16.0476 20L17.901 14.1758H11.8774L13.475 16.41Z"
      fill="#EDEEE9"
    />
  </svg>
);
