import * as types from "./actionTypes";

export const fetchDocuments = (themes, id) => ({
  type: types.DOCUMENTS_FETCH,
  themes,
  id,
});

export const fetchDocumentsSuccess = (payload) => ({
  type: types.DOCUMENTS_FETCH_SUCCESS,
  payload,
});

export const resetDocuments = () => ({
  type: types.DOCUMENTS_RESET,
});
