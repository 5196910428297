import React from "react";

export default (props) => (
  <svg
    width={props.width || "30"}
    height={props.height || "30"}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 26.345L18.52 14.9762L29.885 3.50875L26.345 0L14.9825 11.4738L3.5125 0.11375L0 3.62625L11.4825 15.0075L0.11375 26.4875L3.62625 30L15.0162 18.51L26.4912 29.885L30 26.345Z"
      fill={props.fill || "#222220"}
    />
  </svg>
);
