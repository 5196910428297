import {
  take,
  takeEvery,
  put,
  fork,
  all,
  select,
  call,
  delay,
} from "redux-saga/effects";

import { themes, types, parts, data } from "../data";

import { filterArrBy, sortByDateTimeAndYear } from "./utils";
import api from "./api";

import home from "../reducers/home";
import container from "../reducers/container";
import page from "../reducers/page";
import search from "../reducers/search";
import documents from "../reducers/documents";
import addresses from "../reducers/addresses";
import channels from "../reducers/channels";
import channel from "../reducers/channel";
import progress from "../reducers/progress";
import filters from "../reducers/filters";
import errors from "../reducers/errors";

const params = {
  theme: themes,
  type: types,
  part: parts,
};

const getHomeState = ({ home }) => home;
const getSearchState = ({ search }) => search;
const getContainerState = ({ container }) => container;
const getFiltersState = ({ filters }) => filters;
const getChannelState = ({ channel }) => channel;
const getAllowance = ({ allowance }) => allowance;

function* fetchHome() {
  const { limit } = yield select(getHomeState);
  const total = Math.ceil(data.length / limit);
  const min = 0;
  const max = min + limit;

  let temp = data.sort((a, b) => sortByDateTimeAndYear(a, b)).slice(min, max);
  yield put(home.actions.fetchHomeSuccess({ data: temp, total }));
}

function* fetchHomeNext() {
  let { offset, limit } = yield select(getHomeState);
  const min = limit * offset;
  const max = min + limit;
  const temp = data.sort((a, b) => sortByDateTimeAndYear(a, b)).slice(min, max);

  yield delay(300);
  yield put(home.actions.fetchHomeNextSuccess({ data: temp }));
}

function* fetchContainer(config) {
  const { limit } = yield select(getContainerState);
  const min = 0;
  const max = min + limit;

  const temp = data
    .filter((a) => a[config.key].includes(config.name))
    .sort((a, b) => sortByDateTimeAndYear(a, b));
  const total = Math.ceil(temp.length / limit);
  const slicedTemp = temp.slice(min, max);

  yield put(
    container.actions.fetchContainerSuccess({ data: slicedTemp, total })
  );
}

function* fetchContainerNext() {
  const { limit, offset, config } = yield select(getContainerState);
  const min = limit * offset;
  const max = min + limit;

  const temp = data
    .filter((a) => a[config.key].includes(config.name))
    .sort((a, b) => sortByDateTimeAndYear(a, b))
    .slice(min, max);

  yield delay(300);
  yield put(container.actions.fetchContainerNextSuccess({ data: temp }));
}

function* fetchDocuments(themes, id) {
  let temp = [];
  const filteredDataById = data.filter((b) => b.id !== id);
  const themesArray = themes.split(",");
  themesArray.forEach((theme) => {
    const filteredByTheme = filteredDataById.filter((a) =>
      a.theme.toString().includes(theme)
    );

    temp = [...temp, ...filteredByTheme];
  });

  temp = [...new Set(temp.map((item) => item))];

  yield put(documents.actions.fetchDocumentsSuccess({ data: temp }));
}

function* fetchPage(id) {
  const temp = data.filter((a) => a.id === id);
  yield put(page.actions.fetchPageSuccess({ data: temp }));
}

function* fetchSearch() {
  const {
    limit,
    config: { theme, type, part, year, query },
  } = yield select(getSearchState);

  const min = 0;
  const max = min + limit;
  let temp;

  if (theme || type || part || year || query) {
    if (part) {
      temp = filterArrBy(data, part, "part", params["part"]);
    }

    if (theme) {
      temp = filterArrBy(temp ? temp : data, theme, "theme", params["theme"]);
    }

    if (type) {
      temp = filterArrBy(temp ? temp : data, type, "type", params["type"]);
    }

    if (year) {
      temp = filterArrBy(temp ? temp : data, year, "year");
    }

    if (query) {
      const prevArray = temp ? temp : data;
      const arrayOfObjectsWithAction = [];
      let nextArray1 = [],
        nextArray2 = [],
        nextArray3 = [];

      const response = yield call(api.fetchSearchApi, query);
      const responseData = Object.values(response.data);

      if (!!responseData.length) {
        prevArray.forEach((obj) => {
          let isNotInObject = true;

          obj.actions.forEach((action, i) => {
            if (responseData.includes(action.id.toString())) {
              arrayOfObjectsWithAction.push({
                ...obj,
                id: obj.id + "." + (i + 1),
                actions: action,
              });

              isNotInObject = false;
            }
          });

          if (isNotInObject) nextArray1.push(obj);
        });
      } else nextArray1 = prevArray;

      nextArray1.forEach((obj) => {
        let isNotInObject = true;

        obj.actions.forEach((action, i) => {
          if (action.text.toString().toLowerCase().includes(query)) {
            arrayOfObjectsWithAction.push({
              ...obj,
              id: obj.id + "." + (i + 1),
              actions: action,
            });

            isNotInObject = false;
          }
        });

        if (isNotInObject) nextArray2.push(obj);
      });

      nextArray2.forEach((obj) => {
        const { description, title } = obj;

        if (
          title.toLowerCase().includes(query) ||
          description.toString().toLowerCase().includes(query)
        ) {
          nextArray3.push(obj);
        }
      });

      temp = [...nextArray3, ...arrayOfObjectsWithAction];
    }
  } else temp = data;

  const total = temp.length < limit ? 1 : Math.ceil(temp.length / limit);
  temp = temp.sort((a, b) => sortByDateTimeAndYear(a, b)).slice(min, max);

  yield delay(300);
  yield put(search.actions.fetchSearchSuccess({ data: temp, total }));
}

function* fetchSearchNext() {
  const {
    limit,
    offset,
    config: { theme, type, part, year, query },
  } = yield select(getSearchState);

  const min = limit * offset;
  const max = min + limit;

  let temp;

  if (theme || type || part || year || query) {
    if (part) {
      temp = filterArrBy(data, part, "part", params["part"]);
    }

    if (theme) {
      temp = filterArrBy(temp ? temp : data, theme, "theme", params["theme"]);
    }

    if (type) {
      temp = filterArrBy(temp ? temp : data, type, "type", params["type"]);
    }

    if (year) {
      temp = filterArrBy(temp ? temp : data, year, "year");
    }

    if (query) {
      const prevArray = temp ? temp : data;
      const arrayOfObjectsWithAction = [];
      let nextArray1 = [],
        nextArray2 = [],
        nextArray3 = [];

      const response = yield call(api.fetchSearchApi, query);
      const responseData = Object.values(response.data);

      if (!!responseData.length) {
        prevArray.forEach((obj) => {
          let isNotInObject = true;

          obj.actions.forEach((action, i) => {
            if (responseData.includes(action.id.toString())) {
              arrayOfObjectsWithAction.push({
                ...obj,
                id: obj.id + "." + (i + 1),
                actions: action,
              });

              isNotInObject = false;
            }
          });

          if (isNotInObject) nextArray1.push(obj);
        });
      } else nextArray1 = prevArray;

      nextArray1.forEach((obj) => {
        let isNotInObject = true;

        obj.actions.forEach((action, i) => {
          if (action.text.toString().toLowerCase().includes(query)) {
            arrayOfObjectsWithAction.push({
              ...obj,
              id: obj.id + "." + (i + 1),
              actions: action,
            });

            isNotInObject = false;
          }
        });

        if (isNotInObject) nextArray2.push(obj);
      });

      nextArray2.forEach((obj) => {
        const { description, title } = obj;

        if (
          title.toLowerCase().includes(query) ||
          description.toString().toLowerCase().includes(query)
        ) {
          nextArray3.push(obj);
        }
      });

      temp = [...nextArray3, ...arrayOfObjectsWithAction];
    }
  } else {
    temp = data;
  }

  temp = temp.sort((a, b) => sortByDateTimeAndYear(a, b)).slice(min, max);

  yield delay(300);
  yield put(search.actions.fetchSearchNextSuccess({ data: temp }));
}

function* fetchAddresses() {
  const { data } = yield call(api.fetchAddresses);

  yield put(addresses.actions.fetchAddressesSuccess(data.response.data));
}

function* fetchFilters() {
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const response = yield call(api.fetchFilters, checkTokenExpiredYesUpdate);

  const { libraries, names, platforms, departments } = response.data.data;

  const data = {
    names,
    libraries,
    platforms,
    departments,
    status: ["Не автоматизирован", "Автоматизирован"],
    relevancy: ["Не актуален", "Актуален"],
    automation_check: [
      "Не проверено",
      "Автоматизация не настроена",
      "Автоматизация настроена",
    ],
  };

  yield put(filters.actions.getFiltersSuccess(data));
}

function* fetchChannels() {
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { parsed, pages } = yield select(getFiltersState);
  let response;

  if (typeof window !== "undefined") {
    yield put(progress.actions.clearProgress());
    yield put(progress.actions.startProgress());
  }

  response = yield call(
    api.fetchChannels,
    parsed,
    pages,
    checkTokenExpiredYesUpdate
  );

  if (typeof window !== "undefined") {
    yield put(progress.actions.finishProgress());
    yield delay(300);
    yield put(progress.actions.closeProgress());
  }

  yield put(channels.actions.getChannelsSuccess(response.data.data));
  yield put(filters.actions.setPagesFilters(response.data.pages));
}

function* updateChannel() {
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { data } = yield select(getChannelState);
  let resp;

  if (!!data.id) {
    resp = yield call(api.patchChannel, data, checkTokenExpiredYesUpdate);
  } else {
    data.automation_check = 0;
    resp = yield call(api.putChannel, data, checkTokenExpiredYesUpdate);
  }

  if (!!resp.data) {
    yield put(channel.actions.updateChannelSuccess());
  } else {
    const error = resp.response.data.message;

    yield put(errors.actions.setError(error));
  }
}

function* watchFetchContainer() {
  while (true) {
    const { config } = yield take(container.types.CONTAINER_FETCH);
    yield fork(fetchContainer, config);
  }
}

function* watchFetchContainerNext() {
  yield takeEvery(container.types.CONTAINER_FETCH_NEXT, fetchContainerNext);
}

function* watchFetchDocuments() {
  while (true) {
    const { themes, id } = yield take(documents.types.DOCUMENTS_FETCH);
    yield fork(fetchDocuments, themes, id);
  }
}

function* watchFetchPage() {
  while (true) {
    const { id } = yield take(page.types.PAGE_FETCH);
    yield fork(fetchPage, id);
  }
}

function* watchFetchHome() {
  yield takeEvery(home.types.HOME_FETCH, fetchHome);
}

function* watchFetchHomeNext() {
  yield takeEvery(home.types.HOME_FETCH_NEXT, fetchHomeNext);
}

function* watchFetchSearch() {
  yield takeEvery(search.types.SEARCH_FETCH, fetchSearch);
}

function* watchFetchSearchNext() {
  yield takeEvery(search.types.SEARCH_FETCH_NEXT, fetchSearchNext);
}

function* watchFetchAddresses() {
  yield takeEvery(addresses.types.ADDRESSES_FETCH, fetchAddresses);
}

function* watchFetchChannels() {
  yield takeEvery(channels.types.CHANNELS_GET, fetchChannels);
}

function* watchUpdateChannel() {
  yield takeEvery(channel.types.CHANNEL_UPDATE, updateChannel);
}

function* watchFetchFilters() {
  yield takeEvery(filters.types.GET_FILTERS, fetchFilters);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchHome),
    fork(watchFetchHomeNext),
    fork(watchFetchContainer),
    fork(watchFetchContainerNext),
    fork(watchFetchDocuments),
    fork(watchFetchPage),
    fork(watchFetchSearch),
    fork(watchFetchSearchNext),
    fork(watchFetchAddresses),
    fork(watchFetchChannels),
    fork(watchFetchFilters),
    fork(watchUpdateChannel),
  ]);
}
