import React from "react";

export default (props) => (
  <svg
    {...props}
    viewBox="0 0 34 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-1216 -15)">
      <g id="Group">
        <g>
          <path
            d="M 0 0L 34 0L 34 -3L 0 -3L 0 0Z"
            transform="translate(1216 18)"
          />
        </g>
        <g>
          <path
            d="M 0 0L 34 0L 34 -3L 0 -3L 0 0Z"
            transform="translate(1216 29)"
          />
        </g>
        <g>
          <path
            d="M 0 0L 34 0L 34 -3L 0 -3L 0 0Z"
            transform="translate(1216 40)"
          />
        </g>
      </g>
    </g>
  </svg>
);
