import * as types from "./actionTypes";

export const fetchAddresses = () => ({
  type: types.ADDRESSES_FETCH,
});

export const fetchAddressesSuccess = (payload) => ({
  type: types.ADDRESSES_FETCH_SUCCESS,
  payload,
});
