import React from "react";
import styled from "styled-components";

import Rectangle from "../Rectangle";
import BtnPart from "../BtnPart";
import Icon, { icons } from "../Icon";

const Card = styled(Rectangle)`
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;

  svg {
    position: absolute;
  }

  svg {
    top: -3px;
    right: -3px;
  }
`;

const CardText = styled.div`
  display: block;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #24627a;
  padding-right: 40px;
  padding-top: 20px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;

  @media (min-width: 769px) {
    padding-left: 20px;

    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 768px) {
    padding-left: 15px;

    font-size: 14px;
    line-height: 17px;
  }
`;

const PageRect = styled.div`
  position: absolute;
  z-index: 111;
  border-left: 1px solid ${({ colorTheme }) => colorTheme.primary};
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    :hover > div {
      background-color: ${({ colorTheme }) => colorTheme.primary};
      border-color: ${({ colorTheme }) => colorTheme.primary};
      color: ${({ colorTheme }) => colorTheme.secondary};
    }
  }

  > p {
    color: ${({ colorTheme }) => colorTheme.primary};
  }

  > div {
    background-color: initial;
    border-color: initial;
    color: #ffffff;
  }

  @media (min-width: 569px) {
    width: calc(100% - 40px);

    margin: 20px 20px 0;
    bottom: calc(20px + 30px + 20px);
  }

  @media (max-width: 568px) {
    width: calc(100% - 30px);

    margin: 15px 15px 0;
    bottom: calc(15px + 30px + 15px);
  }
`;

const PageRectText = styled.p`
  margin: 0 0 10px 0;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (min-width: 569px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 568px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const PASBtn = styled(BtnPart)`
  padding: 0 10px;

  @media (min-width: 769px) {
    bottom: 20px;
    left: 20px;
    height: 26px;
  }

  @media (max-width: 768px) {
    bottom: 15px;
    left: 15px;
    height: 24px;
  }
`;

const PageRectBtn = styled(PASBtn)`
  border-width: 1px;
  border-style: solid;
  width: fit-content;
`;

const CardPart = styled(PASBtn)`
  position: absolute;
  z-index: 1;

  :hover {
    background-color: ${({ colorTheme }) => colorTheme.primary};
    border-color: ${({ colorTheme }) => colorTheme.primary};
    color: ${({ colorTheme }) => colorTheme.secondary};
  }

  border-color: ${({ colorTheme }) => colorTheme.third};
  color: ${({ colorTheme }) => colorTheme.third};
`;

const CardImage = styled.img`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: cover;

  height: calc(50% + 3px);
  width: calc(100% + 0.75px);
  filter: brightness(0.7);
`;

const PageAtSearch = ({
  pageProps: {
    pageId,
    title,
    partName,
    partPath,
    actions,
    imageUrl,
    onPageClick,
    colorIfImageUrlOrNot,
  },
}) => {
  const { text, btn } = actions;
  const cardBtnPartProps = {
    as: "a",
    target: "_blank",
    rel: "noopener noreferrer",
    colorTheme: colorIfImageUrlOrNot,
  };

  return (
    <Card onClick={(e) => onPageClick(e, pageId)}>
      <CardText>{title}</CardText>
      <PageRect {...cardBtnPartProps} href={btn.url}>
        <PageRectText>{text}</PageRectText>
        <PageRectBtn>{btn.text}</PageRectBtn>
      </PageRect>
      <CardPart {...cardBtnPartProps} href={partPath}>
        {partName}
      </CardPart>
      {!!imageUrl && (
        <CardImage src={process.env.REACT_APP_IMG_URL + imageUrl} alt={title} />
      )}
      <Icon icon={icons.triangle} />
    </Card>
  );
};

export default PageAtSearch;
