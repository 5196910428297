import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import rootReducer from "../reducers";

export default function runStore(initialState) {
  const middleware = createSagaMiddleware();
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(middleware))
  );

  store.runSaga = middleware.run;
  store.close = () => store.dispatch(END);

  return store;
}
