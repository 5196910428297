import React, { useEffect, useState } from "react";
import Icon, { icons } from "../../../components/Icon";
import styled from "styled-components";

import { useHistory, useLocation } from "react-router-dom";
import objectToUrlConstructor from "../../../helpers/objectToUrlConstructor";
import { connect } from "react-redux";
import { isEmptyObj } from "../../../sagas/utils";
import qs from "qs";

const Search = ({ className, placeholder, parsed }) => {
  const { search, pathname } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const history = useHistory();
  const [state, setState] = useState("");

  const setInputChange = ({ target }) => {
    setState(target.value);
  };

  const submitSearch = (query) => {
    if (!!query) {
      params["title"] = query.split(" ");
      params["group_id"] = query.split(" ");
      params["tracker"] = query.split(" ");
      params["contact_email"] = query.split(" ");
      params["url"] = query.split(" ");
    } else {
      delete params["title"];
      delete params["group_id"];
      delete params["tracker"];
      delete params["contact_email"];
      delete params["url"];
    }

    if (isEmptyObj(params)) {
      history.replace(pathname);
    } else {
      const url = objectToUrlConstructor(params);

      history.replace(`${pathname}?${url}`);
    }
  };

  const resetSearch = () => {
    setState("");
    submitSearch("");
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      submitSearch(state);
    }
  };

  useEffect(() => {
    if (!!parsed.title && parsed.title !== state)
      setState(parsed.title.join(" "));
  }, [parsed.title]);

  return (
    <Container className={className}>
      <input
        id="media_channels_search"
        type="text"
        value={state}
        onChange={setInputChange}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
      />
      {!!state && (
        <IconBlock onClick={resetSearch}>
          <Icon icon={icons.close} />
        </IconBlock>
      )}
      <IconBlock onClick={submitSearch}>
        <Icon icon={icons.search} fill="#9E9D9D" />
      </IconBlock>
    </Container>
  );
};

const mapStateToProps = ({ filters: { parsed } }) => ({
  parsed,
});

export default connect(mapStateToProps)(Search);

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #9e9d9d;
  align-self: flex-end;
  margin-bottom: 25px;

  input {
    display: block;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    margin: 0;

    width: 100%;
    border: none;
    outline: none;

    color: #24627a;

    ::placeholder {
      color: #9e9d9d;
    }
  }

  @media (min-width: 1151px) {
    height: 35px;
    padding: 0 15px;
    border-radius: 20px;
    width: 282px;

    input {
      font-size: 16px;
    }
  }

  @media (max-width: 1150px) and (min-width: 769px) {
    input {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    input {
      font-size: 16px;
    }
  }

  @media (max-width: 1150px) and (min-width: 569px) {
    padding: 12px 20px;
    height: 50px;
  }

  @media (max-width: 568px) {
    padding: 9px 10px;
    height: 40px;

    input::placeholder {
      font-size: 0;
    }
  }
`;

const IconBlock = styled.div`
  display: flex;
  cursor: pointer;

  @media (min-width: 1151px) {
    margin-left: 10px;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 1150px) and (min-width: 569px) {
    margin-left: 20px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  @media (max-width: 568px) {
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
