import React, { useState } from "react";
import styled from "styled-components";
import Icon, { icons } from "../../../components/Icon";

const Selector = ({
  isDisabled = false,
  label,
  state,
  stateName,
  filters,
  onFocus,
  setState,
  className,
  onBlur,
}) => {
  const [isSelector, setSelector] = useState(false);
  let selected, check, save, numb;

  switch (typeof state[stateName]) {
    case "number":
      numb = state[stateName];
      selected = filters[numb];
      check = (key) => {
        return {
          active: numb === +key,
          name: filters[key],
          object: +key,
        };
      };
      save = ({ temp, key }) => {
        temp[stateName] = key;

        return temp;
      };

      break;
    case "boolean":
      numb = state[stateName] ? 1 : 0;
      selected = filters[numb];
      check = (key) => {
        return {
          active: numb === +key,
          name: filters[key],
          object: !state[stateName],
        };
      };
      save = ({ temp, key }) => {
        temp[stateName] = key;

        return temp;
      };
      break;
    case "object":
      if (Array.isArray(state[stateName])) {
        selected = state[stateName].map((m) => m.name).join(", ");
        check = (key) => {
          return {
            active: state[stateName].some((s) => s.id === filters[key].id),
            name: filters[key].name,
            object: {
              ...filters[key],
              id: filters[key].id,
              name: filters[key].name,
            },
          };
        };
        save = ({ temp, key }) => {
          const isInState = temp[stateName].some((s) => s.id === key.id);

          isInState
            ? (temp[stateName] = temp[stateName].filter((f) => f.id !== key.id))
            : (temp[stateName] = [...temp[stateName], key]);

          return temp;
        };
      } else {
        selected = state[stateName].name;
        check = (key) => {
          return {
            active: state[stateName].id === filters[key].id,
            name: filters[key].name,
            object: {
              ...filters[key],
              id: filters[key].id,
              name: filters[key].name,
            },
          };
        };
        save = ({ temp, key }) => {
          temp[stateName] = key;

          if (stateName === "library") {
            if (!!temp["departments"].length) {
              temp["departments"] = [];
            }
          }

          if (stateName === "platform") {
            temp["is_automated"] = ["telegram", "rutube"].includes(
              temp.platform.tag,
            );

            if (!!temp["group_id"]) {
              temp["group_id"] = "";
            }

            if (!!temp["tracker"]) {
              temp["tracker"] = "";
            }
          }

          return temp;
        };
      }
      break;
  }

  const handleComponent = () => {
    if (isDisabled) return null;

    setSelector(!isSelector);
    onFocus();
  };

  const handleSelector = (object) => {
    const temp = save({ temp: { ...state }, key: object });

    setState(temp);
  };

  return (
    <Component
      className={className}
      tabIndex="1"
      onBlur={() => {
        onBlur();
        setSelector(false);
      }}
      isSelector={isSelector}
      isDisabled={isDisabled || !Object.keys(filters).length}
    >
      {!!label && <label dangerouslySetInnerHTML={{ __html: label }} />}
      <div>
        <div onClick={handleComponent}>
          <span>{selected || "–"}</span>
          {!isDisabled && <Icon icon={icons.checked} />}
        </div>
        {isSelector && !!Object.keys(filters).length ? (
          <div>
            {Object.keys(filters).map((key, i) => {
              const isActive = check(key).active;
              const className = isActive ? "active-component" : "";

              return (
                <span
                  key={i}
                  className={className}
                  onClick={() => handleSelector(check(key).object)}
                >
                  {check(key).name}
                </span>
              );
            })}
          </div>
        ) : null}
      </div>
    </Component>
  );
};

export default Selector;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > label {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

    > span:nth-child(2) {
      font-size: 13px;
    }
  }

  > div {
    cursor: pointer;
    position: relative;

    div {
      &:nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        max-width: 100%;
        width: 100%;
        height: 36px;

        > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > svg {
          display: flex;
          ${({ isSelector }) => isSelector && "transform: rotate(180deg)"};
        }

        @media (min-width: 701px) {
          padding: 5.5px 10px;
          border-radius: 3px;
          border: 1px solid rgb(158, 157, 157);
          font-size: 18px;

          > span {
            max-width: 90%;
          }
        }

        @media (max-width: 700px) {
          border-radius: 0;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid rgb(158, 157, 157);
          font-size: 16px;
          padding: 5.5px;

          > span {
            max-width: 80vw;
          }
        }
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        row-gap: 1px;
        border-radius: 3px;
        border: 1px solid rgb(158, 157, 157);
        overflow: auto;
        position: absolute;
        top: 41px;
        z-index: 1111;
        background: white;
        max-height: 200px;
        width: 100%;

        scroll-behavior: smooth;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
          display: none;
        }

        > span {
          display: flex;
          height: auto;

          &.active-component {
            background-color: #24627a;
            color: #edeee9;
          }

          @media (hover: hover) {
            :hover {
              cursor: pointer;
              background-color: #24627a;
              color: #edeee9;
            }
          }

          @media (min-width: 701px) {
            font-size: 18px;
            padding: 5.5px 10px;
          }

          @media (max-width: 700px) {
            font-size: 16px;
            padding: 5.5px;
          }
        }
      }
    }
  }
`;
