import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Section from "../../../components/Section";

const Metabase = () => {
  const [iframeUrl, setIframeUrl] = useState("");
  const [loaded, setLoaded] = useState(false);

  const getIframeUrl = async () => {
    const url = process.env.REACT_APP_API_URL + "/metabase";
    const config = { method: "get" };

    const resp = await axios(url, config);

    setIframeUrl(resp.data);
  };

  const onLoad = () => setLoaded(true);

  useEffect(() => {
    getIframeUrl();
  }, []);

  return (
    <Container>
      {!!iframeUrl && (
        <>
          {!loaded && <p>Загружаем статистику...</p>}
          <iframe
            onLoad={onLoad}
            src={iframeUrl}
            allowTransparency
            frameBorder="0"
            width="100%"
            height={loaded ? "1000" : "0"}
            style={{ visibility: loaded ? "visible" : "hidden" }}
          />
        </>
      )}
    </Container>
  );
};

export default Metabase;

const Container = styled(Section)`
  margin-top: 30px;
`;
