const filterArrBy = (array, params, name, obj) => {
  return array.filter((key) => {
    return params
      .split(",")
      .some((s) => key[name].includes(name === "year" ? s : obj[s]));
  });
};

const sortByDateTimeAndYear = (a, b) => {
  return (
    new Date(b.date_time) - new Date(a.date_time) ||
    +b.year.slice(-1) - +a.year.slice(-1)
  );
};

const isEmptyObj = (obj) => Object.values(obj).every((val) => !val);

export { filterArrBy, sortByDateTimeAndYear, isEmptyObj };
