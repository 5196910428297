import React from "react";
import styled from "styled-components";
import { themes, types, parts } from "../../data";
import { Link } from "react-router-dom";
import BtnPart from "../BtnPart";

const PageLinks = styled.div`
  margin-right: 20px;

  div:not(:last-child) {
    @media (min-width: 769px) {
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
`;

const PageLinksBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;

  *:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

const BlockLink = styled(BtnPart)`
  height: fit-content;

  @media (min-width: 769px) {
    padding: 5.5px 10px;
  }

  @media (max-width: 768px) {
    padding: 4px 10px;
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: #24627a;
      border-color: #24627a;
      color: #edeee9;
    }
  }
`;

export default ({ tags }) => {
  const setUrl = (block, btn) => {
    if (block === 0) {
      for (let key in parts) {
        if (parts[key] === btn) return `/search?part=${key}`;
      }
    }

    if (block === 1) {
      for (let key in types) {
        if (types[key] === btn) return `/search?type=${key}`;
      }
    }

    if (block === 2) {
      for (let key in themes) {
        if (themes[key] === btn) return `/search?theme=${key}`;
      }
    }

    if (block === 3) {
      return `/search?year=${btn}`;
    }
  };

  return (
    <PageLinks>
      {tags.map((array, i) => {
        return (
          <PageLinksBlock key={i}>
            {array.map((btn, btnKey) => {
              return (
                <BlockLink
                  as={Link}
                  key={btnKey}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={setUrl(i, btn)}
                >
                  {btn}
                </BlockLink>
              );
            })}
          </PageLinksBlock>
        );
      })}
    </PageLinks>
  );
};
