import styled from "styled-components";

export default styled.section`
  margin: 0 auto;
  max-width: 1280px;

  @media (min-width: 769px) {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;
