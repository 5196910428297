import * as types from "./actionTypes";

const initialState = {
  data: [],
  limit: 6,
  total: 0,
  offset: 1,
  isHomeLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.HOME_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        isHomeLoaded: true,
      };

    case types.HOME_FETCH_NEXT_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        offset: state.offset + 1,
      };

    case types.HOME_RESET:
      return initialState;

    default:
      return state;
  }
};
