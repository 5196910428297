import styled from "styled-components";
import BtnPrimary from "../BtnPrimary";

export default styled(BtnPrimary)`
  align-items: center;
  cursor: pointer;
  color: #9e9d9d;
  border-color: #9e9d9d;

  @media (min-width: 769px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
