import React, { Fragment } from "react";
import styled from "styled-components";
import Section from "../Section";
import { themes } from "../../data";
import { NavLink } from "react-router-dom";

const ThemesTitle = styled(Section)`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #24627a;

  @media (min-width: 769px) {
    font-size: 94px;
    line-height: 111px;
    margin-top: 70px;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 36px;
    margin-top: 60px;
    margin-bottom: 25px;
  }
`;

const ThemesButtons = styled(Section)`
  display: flex;
  width: 100%;

  @media (min-width: 769px) {
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  @media (min-width: 569px) {
    flex-wrap: wrap;
  }

  @media (max-width: 568px) {
    flex-direction: column;
  }
`;

const CardButton = styled.div`
  display: flex;
  height: fit-content;
  border-radius: 20px;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
`;

const ThemeBtn = styled(CardButton)`
  border: 1px solid #9e9d9d;
  color: #24627a;
  cursor: pointer;
  padding: 6px 15px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 19px;

  :not(:last-child) {
    margin-bottom: 10px;
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background: rgb(36, 98, 122);
      color: rgb(237, 238, 233);
    }
  }

  @media (max-width: 568px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  @media (min-width: 569px) {
    margin-right: 10px;

    :not(:last-child) {
      margin-right: 10px;
    }
  }

  @media (max-width: 568px) {
    max-width: 100%;
    text-align: center;
    margin-right: 0;
    justify-content: center;
    height: auto;
  }
`;

export default function () {
  return (
    <Fragment>
      <ThemesTitle>Темы</ThemesTitle>
      <ThemesButtons>
        {Object.keys(themes).map((key) => {
          const activeStyle = {
            background: "#24627A",
            color: "#EDEEE9",
          };

          return (
            <ThemeBtn
              key={key}
              as={NavLink}
              to={`/themes/${key}`}
              activeStyle={activeStyle}
            >
              {themes[key]}
            </ThemeBtn>
          );
        })}
      </ThemesButtons>
    </Fragment>
  );
}
