import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 420.36 189.36"
    fill="#9E9D9D"
  >
    <path
      className="cls-1"
      d="M162.05,216.63H140.88V202.7h21.17a7,7,0,0,1,0,13.93m-.24-19.5H140.88V183.18h24v.94a2.77,2.77,0,1,0,5.54,0V179a1.39,1.39,0,0,0-1.38-1.39H138.11a2.78,2.78,0,0,0-2.77,2.8v39a2.77,2.77,0,0,0,2.77,2.79h23.94a12.51,12.51,0,0,0,12.32-12.54,12.72,12.72,0,0,0-12.56-12.54"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M202.16,294.23,154.8,266.81l-47.36,27.41V133.16h94.72ZM213.31,122h-117V308.9a2.49,2.49,0,0,0,2.49,2.48,2.44,2.44,0,0,0,1.2-.32h0l54.8-31.72,54.79,31.72,0,0a2.32,2.32,0,0,0,1.2.33,2.48,2.48,0,0,0,2.49-2.48Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M257.86,164.07a2.08,2.08,0,0,1-2,2.06H244.11v6.41h5.46c7,0,9.9,3.57,9.9,8.47s-3.08,8.54-10,8.54H241a2.17,2.17,0,0,1-2.14-2.13V163.68a2.17,2.17,0,0,1,2.14-2.13h14.8a2.07,2.07,0,0,1,2,2ZM244.11,177v8.13h5.46c3.15,0,4.51-1.86,4.51-4.06s-1.43-4.07-4.51-4.07Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M267.35,189.69a2.37,2.37,0,0,1-2.52-2.31V163.44A2.17,2.17,0,0,1,267,161.3h1a2.14,2.14,0,0,1,2.13,2.14v19L284,162.88a2.94,2.94,0,0,1,2.77-1.47h1.15a2.36,2.36,0,0,1,2.42,2.17v24.08a2.15,2.15,0,0,1-2.14,2.14h-.91a2.17,2.17,0,0,1-2.17-2.14V169.14l-13.65,18.94a3.4,3.4,0,0,1-3,1.61Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M316.52,164.07a2.08,2.08,0,0,1-2,2.06H302.77v6.41h5.46c7,0,9.9,3.57,9.9,8.47s-3.08,8.54-10,8.54h-8.4a2.17,2.17,0,0,1-2.14-2.13V163.68a2.17,2.17,0,0,1,2.14-2.13h14.8a2.07,2.07,0,0,1,2,2ZM302.77,177v8.13h5.46c3.15,0,4.51-1.86,4.51-4.06s-1.43-4.07-4.51-4.07Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M331,166.13V171c0,12.19-1.47,18.84-8.44,18.84-1.12,0-2.17-.53-2.17-1.79v-1c0-1.33.56-1.93,2.07-2.1,3.11-.6,3.57-4.8,3.57-14.25v-6.48a2.56,2.56,0,0,1,2.8-2.69H343a2.56,2.56,0,0,1,2.8,2.69v23.42a2.15,2.15,0,0,1-2.13,2.14h-.91a2.13,2.13,0,0,1-2.14-2.14V166.13Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M355.55,189.69a2.37,2.37,0,0,1-2.52-2.31V163.44a2.17,2.17,0,0,1,2.13-2.14h.95a2.14,2.14,0,0,1,2.13,2.14v19l13.93-19.57a2.94,2.94,0,0,1,2.77-1.47h1.15a2.36,2.36,0,0,1,2.42,2.17v24.08a2.15,2.15,0,0,1-2.14,2.14h-.91a2.17,2.17,0,0,1-2.17-2.14V169.14l-13.65,18.94a3.4,3.4,0,0,1-3,1.61Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M384.11,175.48c0-8.89,5.81-14.49,14.17-14.49,8.19,0,14.07,5.39,14.07,14.45,0,9.46-6.26,14.71-14.07,14.71S384.11,184.83,384.11,175.48Zm5.35,0c0,6.45,3.64,10.16,8.75,10.16S407,182,407,175.34c0-6.2-3.64-9.91-8.75-9.91S389.46,169.07,389.46,175.51Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M436.82,164.07a2.08,2.08,0,0,1-2,2.06h-6.51v21.53a2.15,2.15,0,0,1-2.14,2.14h-.91a2.16,2.16,0,0,1-2.17-2.14V166.13h-6.47a2.06,2.06,0,0,1-2-2.06v-.49a2.05,2.05,0,0,1,2-2h18.2a2.08,2.08,0,0,1,2,2Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M459.64,187.52a2.08,2.08,0,0,1-2,2H443.47a2.17,2.17,0,0,1-2.14-2.13V163.68a2.17,2.17,0,0,1,2.14-2.13h13.89a2.05,2.05,0,0,1,2,2v.49a2.06,2.06,0,0,1-2,2.06H446.55v7.28h8.05a2,2,0,0,1,2,2v.35a2,2,0,0,1-2,2h-8.05V185h11.06a2.08,2.08,0,0,1,2,2Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M477.73,175l7.49,11.45a1.93,1.93,0,0,1,.32,1.05,2.55,2.55,0,0,1-2.73,2.31,2.81,2.81,0,0,1-2.56-1.4l-6.75-10.65h-3.33v9.91A2.16,2.16,0,0,1,468,189.8h-.91a2.14,2.14,0,0,1-2.13-2.14V163.44a2.14,2.14,0,0,1,2.13-2.14H468a2.16,2.16,0,0,1,2.17,2.14v9.87h3.29l7.07-10.54a2.68,2.68,0,0,1,2.35-1.47c1.57,0,2.73.91,2.73,2a2,2,0,0,1-.42,1.19Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M493.69,189.69a2.38,2.38,0,0,1-2.52-2.31V163.44a2.17,2.17,0,0,1,2.14-2.14h.94a2.15,2.15,0,0,1,2.14,2.14v19l13.93-19.57a2.92,2.92,0,0,1,2.76-1.47h1.16a2.35,2.35,0,0,1,2.41,2.17v24.08a2.14,2.14,0,0,1-2.13,2.14h-.91a2.17,2.17,0,0,1-2.17-2.14V169.14l-13.65,18.94a3.43,3.43,0,0,1-3,1.61Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M253.42,224.59c-1.44,0-2.14-.53-2.91-1.93l-6.4-11.13v18.13a2.16,2.16,0,0,1-2.17,2.14H241a2.15,2.15,0,0,1-2.14-2.14V205.58a2.32,2.32,0,0,1,2.35-2.21h1.22c1.72,0,2.28.25,3.22,1.93l7.88,14,8.65-14a3.12,3.12,0,0,1,3.25-1.86h1a2.28,2.28,0,0,1,2.28,2.17v24.12a2.15,2.15,0,0,1-2.14,2.14h-.91a2.16,2.16,0,0,1-2.17-2.14V211.28l-6.93,11.41c-.84,1.41-1.58,1.9-2.94,1.9Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M274.31,217.48c0-8.89,5.81-14.49,14.18-14.49,8.19,0,14.07,5.39,14.07,14.45,0,9.46-6.27,14.71-14.07,14.71S274.31,226.83,274.31,217.48Zm5.36,0c0,6.45,3.64,10.16,8.75,10.16s8.78-3.71,8.78-10.33c0-6.2-3.64-9.91-8.75-9.91S279.67,211.07,279.67,217.51Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M328.56,228.44V229a2.22,2.22,0,0,1-1.85,2.38,20.49,20.49,0,0,1-5.74.81c-8.51,0-14.53-5.85-14.53-14.5S312.39,203,321.18,203a22.92,22.92,0,0,1,5.56.63,2.19,2.19,0,0,1,1.82,2.31l0,.59a1.72,1.72,0,0,1-2.31,1.68,18.26,18.26,0,0,0-4.66-.59c-5.7,0-9.66,3.6-9.66,9.94s4,9.94,9.73,9.94a16.11,16.11,0,0,0,4.69-.66A1.65,1.65,0,0,1,328.56,228.44Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M346.83,217l7.49,11.45a1.93,1.93,0,0,1,.32,1.05,2.55,2.55,0,0,1-2.73,2.31,2.81,2.81,0,0,1-2.56-1.4l-6.75-10.65h-3.33v9.91a2.16,2.16,0,0,1-2.17,2.14h-.91a2.15,2.15,0,0,1-2.13-2.14V205.44a2.15,2.15,0,0,1,2.13-2.14h.91a2.16,2.16,0,0,1,2.17,2.14v9.87h3.29l7.07-10.54A2.68,2.68,0,0,1,352,203.3c1.57,0,2.73.91,2.73,2a2,2,0,0,1-.42,1.19Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M360.27,229.42V205.68a2.17,2.17,0,0,1,2.14-2.13h7.52c7,0,10.08,3,10.08,7.56a6,6,0,0,1-3.67,5.91,6.4,6.4,0,0,1,4.72,6.58c0,4.59-3.08,8-10,8h-8.61A2.17,2.17,0,0,1,360.27,229.42ZM365.49,208v7.49h4.62c3.11,0,4.51-1.5,4.51-3.53s-1.47-4-4.51-4Zm0,11.31v7.81h5.67c3.15,0,4.51-1.82,4.51-3.92s-1.4-3.89-4.51-3.89Z"
      transform="translate(-96.29 -122.02)"
    />
    <path
      className="cls-1"
      d="M391.91,213.07h6.16c5.92,0,9,4.13,9,9.07a9.05,9.05,0,0,1-9.06,9.41h-9.21a2.16,2.16,0,0,1-2.13-2.13v-24a2.15,2.15,0,0,1,2.13-2.14h.91a2.16,2.16,0,0,1,2.17,2.14Zm0,4.55V227h4.94a4.69,4.69,0,1,0,0-9.38Zm20.69,14.11a2.17,2.17,0,0,1-2.14-2.17V205.44a2.15,2.15,0,0,1,2.14-2.14h.91a2.12,2.12,0,0,1,2.13,2.14v24.12a2.15,2.15,0,0,1-2.13,2.17Z"
      transform="translate(-96.29 -122.02)"
    />
  </svg>
);
