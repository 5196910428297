import styled from "styled-components";

export default styled.div`
  border: 3px solid #24627a;
  position: relative;
  background: #ffffff;

  :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -3px;
    right: -3px;
    border-top: 40px solid #edeee9;
    border-left: 40px solid transparent;
  }
`;
