import React from "react";

export default (props) => (
  <svg {...props} viewBox="0 0 12 18">
    <g id="Canvas" transform="translate(-5349 -495)">
      <g id="Vector">
        <path
          id="path0_fill"
          d="M 6 18L 12 10L 8 10L 8 0L 4 0L 4 10L 0 10L 6 18Z"
          transform="translate(5349 495)"
        />
      </g>
    </g>
  </svg>
);
