import React from "react";
import Icon, { icons } from "../Icon";
import styled from "styled-components";

import { useHistory } from "react-router-dom";
import objectToUrlConstructor from "../../helpers/objectToUrlConstructor";
import { connect } from "react-redux";
import { setSearchConfig } from "../../reducers/search/actions";
import { isEmptyObj } from "../../sagas/utils";

const SubHeaderSearch = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #9e9d9d;

  input {
    display: block;
    font-family: Rubik, sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    margin: 0;

    width: 100%;
    border: none;
    outline: none;

    color: #24627a;

    ::placeholder {
      color: #9e9d9d;
    }
  }

  @media (min-width: 1151px) {
    height: 35px;
    padding: 0 15px;
    border-radius: 20px;
    width: 282px;
    align-self: flex-end;
    margin-bottom: 25px;

    input {
      font-size: 16px;
    }
  }

  @media (max-width: 1150px) and (min-width: 769px) {
    margin-top: 40px;

    input {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;

    input {
      font-size: 16px;
    }
  }

  @media (max-width: 1150px) and (min-width: 569px) {
    padding: 12px 20px;
    height: 50px;
  }

  @media (max-width: 568px) {
    padding: 9px 10px;
    height: 40px;

    input::placeholder {
      font-size: 0;
    }
  }
`;

const IconBlock = styled.div`
  display: flex;
  cursor: pointer;

  @media (min-width: 1151px) {
    margin-left: 10px;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 1150px) and (min-width: 569px) {
    margin-left: 20px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  @media (max-width: 568px) {
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const SearchInput = ({
  config,
  query,
  setSearchConfig,
  className,
  placeholder,
}) => {
  const history = useHistory();

  const setInputChange = (value) => {
    if (value.includes(" ")) return;

    config.query = value ? value.toLowerCase() : "";
    setSearchConfig(config);
  };

  const scrollToInput = (el) => {
    const elFromTop = el.getBoundingClientRect().top;
    const scrolling = elFromTop + window.scrollY - 20;
    const opt = { top: scrolling, behavior: "smooth" };

    window.scrollTo(opt);
  };

  const submitSearch = () => {
    if (isEmptyObj(config)) {
      history.replace(`/`);
    } else {
      const url = objectToUrlConstructor(config);
      history.replace(`/search?${url}`);
    }
  };

  const resetSearch = () => {
    setInputChange("");
    submitSearch();
  };

  const handleKeyPress = ({ key }) => {
    if (key === "Enter") {
      submitSearch();
    }
  };

  return (
    <SubHeaderSearch className={className}>
      <input
        type="text"
        value={query}
        onFocus={({ target }) => scrollToInput(target)}
        onChange={({ target }) => setInputChange(target.value)}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
      />
      {query && (
        <IconBlock onClick={resetSearch}>
          <Icon icon={icons.close} />
        </IconBlock>
      )}
      <IconBlock onClick={submitSearch}>
        <Icon icon={icons.search} fill="#9E9D9D" />
      </IconBlock>
    </SubHeaderSearch>
  );
};

const mapStateToProps = ({ search: { config } }) => ({
  config,
  query: config.query,
});

export default connect(mapStateToProps, { setSearchConfig })(SearchInput);
