import React from "react";
import styled from "styled-components";
import Icon, { icons } from "../../components/Icon";

const LogoGrid = styled.div`
  display: flex;

  @media (min-width: 1281px) {
    flex-wrap: wrap;
  }

  @media (max-width: 1280px) and (min-width: 991px) {
    flex-direction: column;
  }
`;

const Logo = styled.a`
  svg {
    width: auto;
  }

  @media (min-width: 1281px) {
    flex-wrap: wrap;

    :not(:last-child) {
      margin-right: 25px;
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 25px;

    svg {
      height: 54px;
    }
  }

  @media (max-width: 990px) and (min-width: 569px) {
    :not(:last-child) {
      margin-right: 25px;
    }
  }

  @media (max-width: 568px) {
    :not(:last-child) {
      margin-right: 10px;
    }

    svg {
      height: 30px;
    }
  }
`;

export default () => {
  return (
    <LogoGrid>
      <Logo
        href={"https://www.mos.ru/kultura/"}
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Департамент культуры города Москвы"
      >
        <Icon icon={icons.logoDepartment} />
      </Logo>
      <Logo
        href={"https://bibliogorod.ru/"}
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Библиотеки Москвы"
      >
        <Icon icon={icons.logo_biblio_full} />
      </Logo>
      <Logo
        href={"https://nekrasovka.ru/"}
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Библиотека им. Н.А. Некрасова"
      >
        <Icon icon={icons.logo_nekrasovka_new} />
      </Logo>
    </LogoGrid>
  );
};
