import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Section from "../Section";
import SearchInput from "../../components/SearchInput";
import SearchChannels from "../../containers/MediaChannels/SearchChannels";

export default ({ isNotChannels }) => {
  const description = isNotChannels
    ? "Портал Центральной универсальной научной библиотеки имени Н.А. Некрасова для коллег из библиотек и других учреждений культуры."
    : "Портал с медиа-каналами для библиотек Москвы";

  return (
    <SubHeader>
      <SubHeaderSearchTitle>
        <SubHeaderTitle to="/">Nekrasovka&nbsp;PRO</SubHeaderTitle>
        {isNotChannels ? (
          <SubHeaderSearchInput placeholder="Поиск" />
        ) : (
          <SubHeaderSearchChannels placeholder="Поиск" />
        )}
      </SubHeaderSearchTitle>
      <SubHeaderSubTitle>{description}</SubHeaderSubTitle>
    </SubHeader>
  );
};

const SubHeader = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  a,
  p {
    color: #24627a;
    font-style: normal;
  }

  @media (min-width: 769px) {
    margin-top: 35px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const SubHeaderTitle = styled(Link)`
  font-family: Rubik, sans-serif;
  font-weight: 900;
  width: min-content;

  @media (min-width: 872px) {
    font-size: 94px;
  }

  @media (max-width: 871px) and (min-width: 471px) {
    font-size: 8vw;
  }

  @media (max-width: 470px) {
    font-size: 33px;
  }
`;

const SubHeaderSubTitle = styled.p`
  margin: 10px 0 0 0;
  max-width: 990px;

  font-family: KazimirText, sans-serif;
  font-weight: normal;

  @media (min-width: 769px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const SubHeaderSearchInput = styled(SearchInput)`
  @media (max-width: 1150px) {
    display: none;
  }
`;

const SubHeaderSearchChannels = styled(SearchChannels)`
  @media (max-width: 1150px) {
    display: none;
  }
`;

const SubHeaderSearchTitle = styled.div`
  @media (min-width: 1151px) {
    display: flex;
    justify-content: space-between;
  }
`;
