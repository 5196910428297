import React from "react";
import styled from "styled-components";
import { themes, parts } from "../../data";
import Section from "../../components/Section";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setMenu } from "../../reducers/menu/actions";

const MenuContainer = styled.div`
  @media (min-width: 569px) {
    display: none;
  }

  @media (max-width: 568px) {
    display: block;
    background: #24627a;

    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    right: 0;
    left: 0;
    z-index: 12000;

    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-track {
      background: inherit;
    }

    ::-webkit-scrollbar-thumb {
      background: inherit;
    }

    transform: translateX(${(props) => (props.isMenuOpen ? "0" : "-100%")});
    transition: transform linear 0.2s;
  }
`;

const ContainerMain = styled(Section)`
  margin: 25px 0;
  height: 1000px;

  @media (min-width: 569px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const MenuTitle = styled.div`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 25px;
  margin-top: 45px;

  color: #edeee9;
`;

const CardButton = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  border-radius: 20px;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
`;

const ModalBtn = styled(CardButton)`
  border: 1px solid #edeee9;
  color: #edeee9;
  cursor: pointer;
  padding: 4px 10px;
  margin-bottom: 10px;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: 569px) {
    margin-right: 10px;
  }

  @media (max-width: 568px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    justify-content: center;
  }
`;

const ModalMenu = ({ isMenuOpen, setMenu }) => {
  const history = useHistory();

  const onPartsClick = (item) => {
    history.replace(`/parts/${item}`);
    setMenu();
  };

  const onThemesClick = (item) => {
    history.replace(`/themes/${item}`);
    setMenu();
  };

  return (
    <MenuContainer isMenuOpen={isMenuOpen}>
      <ContainerMain>
        {Object.keys(parts).map((item) => {
          return (
            <ModalBtn key={item} onClick={() => onPartsClick(item)}>
              {parts[item]}
            </ModalBtn>
          );
        })}
        <MenuTitle>Темы</MenuTitle>
        {Object.keys(themes).map((item) => {
          return (
            <ModalBtn key={item} onClick={() => onThemesClick(item)}>
              {themes[item]}
            </ModalBtn>
          );
        })}
      </ContainerMain>
    </MenuContainer>
  );
};

const mapStateToProps = ({ menu: { isMenuOpen } }) => ({
  isMenuOpen,
});

export default connect(mapStateToProps, { setMenu })(ModalMenu);
