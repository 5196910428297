import React from "react";
import Icon, { icons } from "../Icon";
import styled from "styled-components";

const LogosContainer = styled.div`
  display: flex;
  height: 100%;

  @media (min-width: 769px) {
    padding-bottom: 19px;
  }

  @media (max-width: 768px) {
    padding-bottom: 11px;
  }

  @media (max-width: 568px) {
    padding-bottom: 14px;
  }
`;

const LogosLink = styled.a`
  width: auto;

  :not(:nth-child(2)) {
    align-items: flex-end;
    display: flex;
  }

  :nth-child(2) {
    height: 100%;

    svg {
      height: 100%;
    }
  }

  @media (min-width: 769px) {
    :not(:first-child) {
      margin-left: 20px;
    }
  }

  @media (max-width: 768px) {
    :not(:nth-child(2)) {
      svg {
        height: 30px;
        width: auto;
      }
    }

    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export default function () {
  return (
    <LogosContainer>
      <LogosLink
        href="https://nekrasovka.ru"
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Библиотека им. Н.А. Некрасова"
      >
        <Icon icon={icons.logoNekrasovka} />
      </LogosLink>
      <LogosLink
        href="https://bibliogorod.ru/"
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Библиотеки Москвы"
      >
        <Icon icon={icons.logoBiblio} width={25} height={110} fill="#9E9D9D" />
      </LogosLink>
      <LogosLink
        href="https://www.mos.ru/kultura/"
        rel="noopener noreferrer"
        target="_blank"
        aria-label="Департамент культуры города Москвы"
      >
        <Icon icon={icons.logoDepartment} />
      </LogosLink>
    </LogosContainer>
  );
}
