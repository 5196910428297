import * as types from "./actionTypes";

export const setFilters = (payload) => ({
  type: types.SET_FILTERS,
  payload,
});

export const setParsedFilters = (payload) => ({
  type: types.SET_PARSED_FILTERS,
  payload,
});

export const setPagesFilters = (payload) => ({
  type: types.SET_PAGES_FILTERS,
  payload,
});

export const getFilters = () => ({
  type: types.GET_FILTERS,
});

export const getFiltersSuccess = (payload) => ({
  type: types.GET_FILTERS_SUCCESS,
  payload,
});

export const resetFilter = () => ({
  type: types.RESET_FILTER,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});
