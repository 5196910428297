import React, { useContext, useEffect } from "react";
import { KeycloakContext } from "react-keycloak-nekrasovka";
import { connect } from "react-redux";
import { getAllowance } from "../../reducers/allowance/actions";
import { Route } from "react-router-dom";
import AlertProvider from "../../nekrasovka-ui/Alert/AlertProvider";

const KeycloakRoute = ({
  isAllowanceLoading,
  getAllowance,
  path,
  component,
  exact,
}) => {
  const kc = useContext(KeycloakContext);

  useEffect(() => {
    if (isAllowanceLoading) return;

    getAllowance({ ...kc });
  }, [isAllowanceLoading]);

  return (
    <AlertProvider>
      <Route exact={exact} path={path} component={component} />
    </AlertProvider>
  );
};

const mapStateToProps = ({ allowance: { isAllowanceLoading } }) => ({
  isAllowanceLoading,
});

export default connect(mapStateToProps, {
  getAllowance,
})(KeycloakRoute);
