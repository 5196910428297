import React from "react";

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.48 124.48">
    <g id="oracal">
      <path
        className="cls-1"
        d="M62.24,0a62.24,62.24,0,1,0,62.24,62.24A62.25,62.25,0,0,0,62.24,0ZM94.71,38,84.07,98.55a2.37,2.37,0,0,1-3.69,1.53L55.25,82.45a4.1,4.1,0,0,1-.81-6S72.61,57.41,72.8,57.16a1.09,1.09,0,0,0,.13-1.27c-.28-.35-.88-.34-1.46,0L41.24,74a4.11,4.11,0,0,1-3.13.58L20.65,70.75a2.37,2.37,0,0,1-.43-4.48L91.44,35.43A2.36,2.36,0,0,1,94.71,38Z"
        fill={props.fill}
      />
    </g>
  </svg>
);
