import * as types from "./actionTypes";

export const fetchHome = () => ({
  type: types.HOME_FETCH,
});

export const fetchHomeSuccess = (payload) => ({
  type: types.HOME_FETCH_SUCCESS,
  payload,
});

export const fetchHomeNext = () => ({
  type: types.HOME_FETCH_NEXT,
});

export const fetchHomeNextSuccess = (payload) => ({
  type: types.HOME_FETCH_NEXT_SUCCESS,
  payload,
});

export const resetHome = () => ({
  type: types.HOME_RESET,
});
