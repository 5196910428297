import React from "react";
import styled from "styled-components";
import Section from "../Section";
import Page from "../Page";
import PageAtSearch from "../PageAtSearch";
import { useHistory, useParams } from "react-router";

export default ({ data }) => {
  const { part } = useParams();
  const history = useHistory();

  const isNotPartInPath = !part;
  let partPath = null;

  const parts = [
    { part_id: 1, name: "Методические материалы" },
    { part_id: 7, name: "Исследования" },
    { part_id: 2, name: "Публикации" },
    { part_id: 3, name: "События" },
    { part_id: 6, name: "Подкасты" },
    { part_id: 4, name: "Образовательные программы" },
  ];

  const imageUrlColors = [
    {
      primary: "#FFFFFF",
      secondary: "#24627a",
      third: "#FFFFFF",
    },
    {
      primary: "#24627a",
      secondary: "#edeee9",
      third: "#9E9D9D",
    },
  ];

  const onPageClick = ({ target }, pageId) => {
    const isElementLink =
      target.parentNode.tagName === "A" || target.tagName === "A";
    if (isElementLink) return;

    history.replace(`/documents/${pageId}`);

    setTimeout(() => {
      const element = document.getElementById(`document_${pageId}`);

      var headerOffset = 30;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 300);
  };

  return (
    <Grid>
      {data.map((page, i) => {
        const { title, part, image, actions, id } = page;
        const isQueryKey = id.includes(".");

        const colorIfImageUrlOrNot = imageUrlColors[!!image.url ? 0 : 1];

        if (isNotPartInPath) {
          const partId = parts.filter((p) => p.name === part[0])[0]["part_id"];
          partPath = `/parts/${partId}`;
        }

        const pageProps = {
          title,
          partPath,
          colorIfImageUrlOrNot,
          partName: part,
          actions: isQueryKey && actions,
          imageUrl: image.url,
          pageId: isQueryKey ? id.split(".")[0] : id,
          onPageClick,
        };

        return isQueryKey ? (
          <PageAtSearch key={i + "_" + id} pageProps={pageProps} />
        ) : (
          <Page key={i + "_" + id} pageProps={pageProps} />
        );
      })}
    </Grid>
  );
};

const Grid = styled(Section)`
  display: grid;

  @media (min-width: 769px) {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 50px;
  }

  @media (min-width: 769px) {
    grid-template-columns: repeat(auto-fill, minmax(386px, 1fr));
  }

  @media (max-width: 768px) and (min-width: 569px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 768px) {
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

    margin-top: 35px;
  }
`;
