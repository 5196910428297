import React, { Component, Fragment } from "react";
import qs from "qs";
import { connect } from "react-redux";
import PagesLoadMore from "../../components/PagesLoadMore";
import {
  fetchSearch,
  resetSearch,
  setSearchConfig,
  fetchSearchNext,
} from "../../reducers/search/actions";
import Pages from "../../components/Pages";
import Section from "../../components/Section";
import styled from "styled-components";

class Search extends Component {
  constructor(props) {
    super(props);
    const { query, theme, part, type, year } = qs.parse(
      props.routeProps.location.search,
      {
        ignoreQueryPrefix: true,
      }
    );

    if (query || theme || type || part || year) {
      props.setSearchConfig({ query, theme, part, type, year });
    }
    props.fetchSearch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { query, theme, type, part, year } = qs.parse(
      this.props.routeProps.location.search,
      {
        ignoreQueryPrefix: true,
      }
    );

    const prev = qs.parse(prevProps.routeProps.location.search, {
      ignoreQueryPrefix: true,
    });

    if (
      prev.query !== query ||
      prev.theme !== theme ||
      prev.type !== type ||
      prev.part !== part ||
      prev.year !== year
    ) {
      this.props.resetSearch();
      this.props.setSearchConfig({ query, theme, part, type, year });
      this.props.fetchSearch();
    }
  }

  componentWillUnmount() {
    this.props.resetSearch();
  }

  render() {
    const {
      isSearchLoaded,
      data,
      offset,
      total,
      fetchSearchNext,
      config: { query },
    } = this.props;

    return (
      <Fragment>
        {isSearchLoaded ? (
          <Fragment>
            {data.length ? (
              <Pages data={data} />
            ) : (
              query && (
                <PreResultSection>
                  По запросу «{query}» ничего не найдено. Попробуйте изменить
                  запрос.
                </PreResultSection>
              )
            )}
          </Fragment>
        ) : (
          query && <PreResultSection>Ищем по слову «{query}»</PreResultSection>
        )}
        {offset < total && <PagesLoadMore onBtnClick={fetchSearchNext} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  search: { isSearchLoaded, data, config, offset, total },
}) => ({
  isSearchLoaded,
  data,
  config,
  offset,
  total,
});

export default connect(mapStateToProps, {
  fetchSearch,
  resetSearch,
  setSearchConfig,
  fetchSearchNext,
})(Search);

const PreResultSection = styled(Section)`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #24627a;

  @media (min-width: 569px) {
    margin-top: 50px;
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 568px) {
    margin-top: 35px;
    font-size: 18px;
    line-height: 21px;
  }
`;
