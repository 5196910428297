import React, { useState } from "react";
import styled from "styled-components";
import Section from "../../components/Section";
import InfoLeft from "./InfoLeft";
import InfoRight from "./InfoRight";
import Icon, { icons } from "../../components/Icon";
import Separator from "../Separator";

const Info = styled(Section)`
  @media (min-width: 991px) {
    display: grid;
    grid-template-columns: calc(100% / 3 - 20px) 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 569px) {
    margin-top: 35px;
  }
`;

const InfoRightHide = styled.div`
  ${(props) =>
    props.hide ? "display: none;" : "display: block; margin-bottom: 40px;"};
  margin-top: 15px;
`;

const HideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: #24627a;

  ${({ hide }) => !hide && "svg {transform: rotate(90deg);}"};
`;

const InfoMobile = styled.div`
  @media (min-width: 569px) {
    display: none;
  }

  @media (max-width: 568px) {
    display: block;
  }
`;

const InfoDesktop = styled.div`
  @media (min-width: 569px) {
    display: block;
  }

  @media (max-width: 568px) {
    display: none;
  }
`;

const InfoSeparator = styled(Separator)`
  margin-top: 0;
  width: calc(100% + 30px);
  position: relative;
  left: -15px;
`;

const InfoLeftLarge = styled(InfoLeft)`
  @media (max-width: 991px) {
    display: none;
  }
`;

const InfoLeftMedium = styled(InfoLeft)`
  @media (min-width: 991px) {
    display: none;
  }

  @media (max-width: 990px) {
    margin-top: 30px;
  }
`;

export default () => {
  const [hide, setHide] = useState(true);

  return (
    <Info>
      <InfoMobile>
        <HideButton onClick={() => setHide(!hide)} hide={hide}>
          Навигация
          <Icon icon={icons.arrow_right} />
        </HideButton>
        <InfoRightHide hide={hide}>
          <InfoRight />
        </InfoRightHide>
        <InfoSeparator />
      </InfoMobile>
      <InfoLeftLarge />
      <InfoDesktop>
        <InfoRight />
      </InfoDesktop>
      <InfoLeftMedium />
    </Info>
  );
};
