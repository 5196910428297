import React from "react";

export default (props) => (
  <svg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3337 15C18.3337 16.8412 16.8412 18.3337 15 18.3337C13.1587 18.3337 11.6663 16.8425 11.6663 15C11.6663 13.1587 13.1587 11.6663 15 11.6663C16.8412 11.6663 18.3337 13.1587 18.3337 15ZM23.1413 10.96C23.1888 12.0137 23.1987 12.33 23.1987 15C23.1987 17.67 23.1888 17.9862 23.1413 19.0413C23.0163 21.7288 21.755 23.0175 19.0425 23.14C17.9875 23.1875 17.6712 23.1987 15 23.1987C12.33 23.1987 12.0125 23.1888 10.96 23.1413C8.2425 23.0175 6.98375 21.7262 6.86125 19.0425C6.8125 17.9862 6.80125 17.67 6.80125 15C6.80125 12.33 6.8125 12.0137 6.86 10.96C6.98375 8.27 8.24625 6.98375 10.9587 6.86125C12.0138 6.8125 12.33 6.8025 15 6.8025C17.67 6.8025 17.9875 6.8125 19.0413 6.86C21.7513 6.98375 23.0163 8.27 23.1413 10.96ZM20.135 15C20.135 12.1637 17.835 9.865 15 9.865C12.1637 9.865 9.865 12.1637 9.865 15C9.865 17.8363 12.165 20.135 15 20.135C17.8363 20.135 20.135 17.8363 20.135 15ZM21.5375 9.6625C21.5375 9 21 8.4625 20.3375 8.4625C19.675 8.4625 19.1375 9 19.1375 9.6625C19.1375 10.325 19.675 10.8625 20.3375 10.8625C21.0013 10.8625 21.5375 10.325 21.5375 9.6625ZM25 0C27.7614 0 30 2.23858 30 5V25C30 27.7614 27.7614 30 25 30H5C2.23858 30 0 27.7614 0 25V5C0 2.23858 2.23858 0 5 0H25ZM25 15C25 12.285 24.9887 11.9437 24.94 10.8775C24.7762 7.25 22.7587 5.2275 19.1237 5.06125C18.0562 5.01125 17.7162 5 15 5C12.2837 5 11.945 5.01125 10.8775 5.06C7.245 5.22625 5.2275 7.24125 5.06 10.8763C5.01125 11.9438 5 12.285 5 15C5 17.7162 5.01125 18.0563 5.06 19.1225C5.2275 22.755 7.2425 24.7737 10.8775 24.94C11.945 24.9887 12.2837 25 15 25C17.7162 25 18.0562 24.9887 19.1237 24.94C22.7512 24.7737 24.7762 22.7588 24.94 19.1225C24.9887 18.0563 25 17.7162 25 15Z"
      fill={props.fill}
    />
  </svg>
);
