import * as types from "./actionTypes";

export const setError = (payload) => ({
  type: types.ERROR_SET,
  payload,
});

export const resetError = (payload) => ({
  type: types.ERROR_RESET,
  payload,
});
