import React from "react";
import { Route, Switch } from "react-router-dom";
import KeycloakProvider from "react-keycloak-nekrasovka";
import Home from "../Home";
import Search from "../Search";
import Container from "../Container";
import Documents from "../Documents";
import MediaChannels from "../MediaChannels";
import KeycloakRoute from "../../components/KeycloakRoute";
import Metabase from "../MediaChannels/Metabase";

export default () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        path="/search"
        render={(routeProps) => <Search routeProps={routeProps} />}
      />
      <Route path="/documents/:document" component={Documents} />
      <Route
        path={[
          "/parts/:part",
          "/themes/:theme",
          "/types/:type",
          "/years/:year",
        ]}
        render={(routeProps) => <Container routeProps={routeProps} />}
      />
      <KeycloakProvider>
        <KeycloakRoute exact path="/channels" component={MediaChannels} />
        <KeycloakRoute path="/channels/metabase" component={Metabase} />
      </KeycloakProvider>
    </Switch>
  );
};
