import React from "react";

export default (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7613 27.0575L22.005 19.3013C23.4638 17.295 24.3263 14.8275 24.3263 12.1625C24.3263 5.45625 18.87 0 12.1625 0C5.45625 0 0 5.45625 0 12.1625C0 18.87 5.45625 24.325 12.1625 24.325C14.705 24.325 17.0663 23.5412 19.0213 22.2025L26.8188 30L29.7613 27.0575ZM3.5675 12.1625C3.5675 7.4225 7.42375 3.56625 12.1638 3.56625C16.9038 3.56625 20.76 7.4225 20.76 12.1625C20.76 16.9025 16.9038 20.7588 12.1638 20.7588C7.4225 20.7588 3.5675 16.9025 3.5675 12.1625Z"
      fill={props.fill || "#24627A"}
    />
  </svg>
);
