import React, { useState } from "react";
import Button from "../Button";
import { icons } from "../Icon";
import styled from "styled-components";
import Section from "../Section";

const GridButtonSection = styled(Section)`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${(props) =>
    props.isIconRotate &&
    `
  svg {
    animation: 2s linear 0s normal none infinite running rot;

    @keyframes rot {
      100% {
        transform: rotate(0deg);
      }
      0% {
        transform: rotate(360deg);
      }
    }
  }`};
`;

export default ({ onBtnClick, className }) => {
  const [isIconRotate, setIconRotate] = useState(false);

  const rotateOnClick = () => {
    setIconRotate(true);
    onBtnClick();

    setTimeout(() => setIconRotate(false), 400);
  };

  return (
    <GridButtonSection isIconRotate={isIconRotate} className={className}>
      <Button
        onBtnClick={rotateOnClick}
        icon={icons.cycle}
        btn_txt="Загрузить страницы"
        btn_bgc="#24627A"
        btn_bc="#24627A"
        btn_c="#EDEEE9"
      />
    </GridButtonSection>
  );
};
