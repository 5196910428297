import React from "react";

export default (props) => (
  <svg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H25C27.7614 30 30 27.7614 30 25V5C30 2.23858 27.7614 0 25 0H5ZM15.3949 17.1021H17.0312V24H19V6H16.0597C13.1705 6 10.7415 7.88811 10.7415 11.6643C10.7415 14.358 11.8153 15.8182 13.4261 16.6238L10 24H12.2756L15.3949 17.1021ZM17.0312 7.63636V15.4909H15.983C14.2699 15.4909 12.8636 14.5594 12.8636 11.6643C12.8636 8.66853 14.3977 7.63636 15.983 7.63636H17.0312Z"
      fill={props.fill}
    />
  </svg>
);
