import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Pages from "../../components/Pages";
import { parts, themes, types } from "../../data";
import {
  fetchContainer,
  resetContainer,
  fetchContainerNext,
} from "../../reducers/container/actions";
import PagesLoadMore from "../../components/PagesLoadMore";
import { Helmet } from "react-helmet";

class Container extends Component {
  constructor(props) {
    super(props);

    const config = this.setConfig(props.routeProps.match.params);
    props.fetchContainer(config);
    this.state = { config };
  }

  setConfig = (params) => {
    const key = Object.keys(params)[0];

    switch (key) {
      case "part":
        return { key, name: parts[params[key]] };
      case "theme":
        return { key, name: themes[params[key]] };
      case "type":
        return { key, name: types[params[key]] };
      case "year":
        return { key, name: params.year };

      default:
        break;
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.routeProps.location.pathname !==
      this.props.routeProps.location.pathname
    ) {
      const config = this.setConfig(this.props.routeProps.match.params);
      this.props.fetchContainer(config);
      this.setState({ config });
    }
  }

  componentWillUnmount() {
    this.props.resetContainer();
  }

  render() {
    const { isPagesLoaded, data, total, offset, fetchContainerNext } =
      this.props;

    const { config } = this.state;

    const content =
      config.key === "year" ? `Публикации за ${config.name}` : config.name;

    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={content} />
          <meta name="title" content={content} />
        </Helmet>
        {isPagesLoaded && <Pages data={data} />}
        {offset < total && <PagesLoadMore onBtnClick={fetchContainerNext} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  container: { data, isPagesLoaded, total, offset },
}) => ({
  data,
  isPagesLoaded,
  total,
  offset,
});

export default connect(mapStateToProps, {
  fetchContainer,
  resetContainer,
  fetchContainerNext,
})(Container);
