import React from "react";

export default (props) => (
  <svg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8477 5.95538e-05H6.15234C5.71876 5.95538e-05 5.33838 5.81685e-05 4.99922 5.59547e-05C2.23787 3.79307e-05 0 2.23854 0 4.99989V25C0 27.7614 2.23752 30 4.99894 30C5.33214 30 5.71158 30 6.15234 30H23.8477C24.3134 30 24.6894 30 25.0023 30C27.7638 30 30 27.7614 30 25V4.99995C30 2.23855 27.7641 2.14359e-05 25.0027 5.38614e-05C24.7031 5.73794e-05 24.3297 5.95538e-05 23.8477 5.95538e-05ZM18.0796 19.9534L17.5362 24.3001C17.4263 25.1797 16.6785 25.8399 15.792 25.8399H14.208C13.3215 25.8399 12.5738 25.1797 12.4638 24.3001L11.9205 19.9533C11.6889 18.101 13.1332 16.4649 15 16.4649C13.5461 16.4649 12.3633 15.282 12.3633 13.8282C12.3633 12.3743 13.5461 11.1915 15 11.1915C16.4539 11.1915 17.6367 12.3743 17.6367 13.8282C17.6367 15.282 16.4539 16.4649 15 16.4649C16.8668 16.4649 18.3111 18.101 18.0796 19.9534ZM15 9.43364C12.2538 9.43364 10.0195 11.6679 10.0195 14.4141C10.0195 15.4039 10.311 16.3264 10.8108 17.1026C10.4515 17.7131 10.2303 18.3949 10.1619 19.099C8.98641 17.8855 8.26172 16.233 8.26172 14.4141C8.26172 10.6986 11.2845 7.67583 15 7.67583C18.7155 7.67583 21.7383 10.6986 21.7383 14.4141C21.7383 16.233 21.0136 17.8855 19.8382 19.099C19.7698 18.395 19.5485 17.7132 19.1892 17.1026C19.689 16.3264 19.9805 15.4039 19.9805 14.4141C19.9805 11.6679 17.7462 9.43364 15 9.43364ZM19.385 23.6817L19.6556 21.5174C21.9669 19.9974 23.4961 17.3812 23.4961 14.4141C23.4961 9.72936 19.6847 5.91802 15 5.91802C10.3153 5.91802 6.50391 9.72936 6.50391 14.4141C6.50391 17.3812 8.03314 19.9974 10.3444 21.5174L10.615 23.6817C7.14867 22.035 4.74609 18.5001 4.74609 14.4141C4.74609 8.7601 9.346 4.16021 15 4.16021C20.654 4.16021 25.2539 8.7601 25.2539 14.4141C25.2539 18.5001 22.8513 22.035 19.385 23.6817Z"
      fill={props.fill}
    />
  </svg>
);
