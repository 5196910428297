import * as types from "./actionTypes";

export const getChannels = () => ({
  type: types.CHANNELS_GET,
});

export const setChannels = (payload) => ({
  type: types.CHANNELS_SET,
  payload,
});

export const getChannelsSuccess = (payload) => ({
  type: types.CHANNELS_GET_SUCCESS,
  payload,
});

export const resetChannels = (payload) => ({
  type: types.CHANNELS_RESET,
  payload,
});
