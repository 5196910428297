import React, { useState } from "react";
import Icon, { icons } from "../../../components/Icon";
import styled from "styled-components";
import Button from "../../../components/Button";
import { connect } from "react-redux";
import { activation, status } from "../bd";
import { setError, resetError } from "../../../reducers/errors/actions";
import {
  setChannel,
  updateChannel,
  resetChannel,
} from "../../../reducers/channel/actions";
import Selector from "../Selector";

const Card = ({
  className,
  isAdmin,
  isActive,
  filters,
  data,
  channel,
  errors,
  setError,
  resetError,
  setChannel,
  updateChannel,
  resetChannel,
}) => {
  let error = {};
  const [state, setState] = useState(data);
  const isDataId = !!state.id;
  const isAutomated = state.is_automated;
  const isErrorInActive = isActive && errors.isError;

  const automation = {
    text: isAutomated
      ? status[state.platform.tag]
      : activation[state.platform.tag] || "Выберите платформу",
    button: {
      text: filters.data.status[isAutomated ? 1 : 0],
      btn_c: isAutomated ? "#EDEEE9" : "#9E9D9D",
      btn_bgc: isAutomated ? "#24627A" : "initial",
    },
  };

  if (isErrorInActive) {
    error = {
      url: !!errors.message.long.url,
      group_id: !!errors.message.long.group_id,
      tracker: !!errors.message.long.tracker,
      contact_name: !!errors.message.long.contact_name,
      contact_email: !!errors.message.long.contact_email,
      contact_phone: !!errors.message.long.contact_phone,
    };
  }

  const departmentsLabel = `
              <span>${
                !!state.departments.length
                  ? `Обособленное структурное подразделение (${state.departments.length}&nbsp;выбрано)`
                  : "Обособленное структурное подразделение (не&nbsp;выбрано)"
              }</span>
              <span>(Список обособленных подразделений указан в соответствии с Уставом учреждения)</span>`;

  const checkIsDisabled = () => {
    const isNotSomeData = [
      state.url,
      state.contact_name,
      state.contact_email,
      state.contact_phone,
      state.title,
      state.platform.id,
      state.library.id,
    ].some((s) => !s);

    const isNotGroupIdIfPlatform =
      (!state.group_id &&
        ["dzen", "vkontakte", "youtube"].includes(state.platform.tag) &&
        !!state.platform.id) ||
      (!state.tracker &&
        state.platform.tag === "curated-website" &&
        !!state.platform.id);

    return isNotSomeData || isNotGroupIdIfPlatform;
  };

  const handleSave = () => {
    let temp = { ...state };

    temp.library_id = temp.library.id;
    temp.platform_id = temp.platform.id;

    temp.departments_copy = temp.departments;
    temp.departments = temp.departments.map((m) => m.id);

    resetError();
    setChannel(temp);
    updateChannel();
  };

  const handleClose = () => {
    resetChannel();
    setState(data);
  };

  const handleFocus = () => {
    if (!channel.isChannel) resetChannel();
    setChannel(data);
  };

  return (
    <CardContainer className={className}>
      <div>
        <CardChild_1>
          <div>
            <textarea
              rows="2"
              placeholder="Название канала *"
              name="title"
              value={state.title}
              onFocus={handleFocus}
              onChange={({ target }) =>
                setState({
                  ...state,
                  title: target.value,
                })
              }
            />
          </div>
          <div>
            <Selector
              label="Юридическое лицо *"
              stateName="library"
              state={state}
              filters={filters.data["libraries"]}
              isDisabled={channel.isChannel ? !isAdmin : !channel.isChannel}
              onFocus={handleFocus}
              setState={setState}
            />
            <Selector
              label={departmentsLabel}
              stateName="departments"
              state={state}
              filters={
                !!state.library.id
                  ? filters.data["departments"].filter(
                      (f) => f.library_id === state.library.id,
                    )
                  : []
              }
              onFocus={handleFocus}
              setState={setState}
            />
          </div>
        </CardChild_1>
        <CardChild_2>
          <div>
            <label>Ссылка на канал *</label>
            <CardInputWithError
              disabled={!!channel.data.id && !isAdmin}
              isError={error.url}
              type="text"
              name="url"
              value={state.url}
              onFocus={handleFocus}
              onChange={({ target }) => {
                setState({
                  ...state,
                  url: target.value,
                });

                if (error.url) {
                  const message = errors.message;
                  delete message.long.url;

                  if (!!Object.keys(message).length) {
                    setError(message);
                  } else resetError();
                }
              }}
            />
            {error.url ? (
              <CardErrorMessage>{errors.message.long.url}</CardErrorMessage>
            ) : null}
          </div>
          <Selector
            label="Актуальность"
            stateName="is_relevant"
            state={state}
            filters={filters.data["relevancy"]}
            onFocus={handleFocus}
            setState={setState}
          />
        </CardChild_2>
        <CardChild_3>
          <Selector
            label="Платформа *"
            stateName="platform"
            state={state}
            filters={filters.data["platforms"]}
            isDisabled={isDataId}
            onFocus={handleFocus}
            setState={setState}
          />
          {["dzen", "vkontakte", "youtube", "curated-website"].includes(
            state.platform.tag,
          ) ? (
            <div>
              <label>
                ID Группы/Счётчика *
                {["vkontakte"].includes(state.platform.tag) && (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_URL +
                      `/help/Как+узнать+ID+страницы+или+группы+в+VK.pdf`
                    }
                  >
                    Как узнать?
                  </a>
                )}
                {["dzen"].includes(state.platform.tag) && (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_URL +
                      `/help/Как+узнать+ID+группы+в+Дзен.pdf`
                    }
                  >
                    Как узнать?
                  </a>
                )}
              </label>
              {["curated-website"].includes(state.platform.tag) && (
                <>
                  <CardInputWithError
                    isError={error.tracker}
                    disabled={!!channel.data.id && !isAdmin}
                    type="text"
                    name="group"
                    value={state.tracker}
                    onFocus={handleFocus}
                    onChange={({ target }) => {
                      setState({
                        ...state,
                        tracker: target.value,
                      });

                      if (error.tracker) {
                        const message = errors.message;
                        delete message.long.tracker;

                        if (!!Object.keys(message).length) {
                          setError(message);
                        } else resetError();
                      }
                    }}
                  />
                  {error.tracker ? (
                    <CardErrorMessage>
                      {errors.message.long.tracker}
                    </CardErrorMessage>
                  ) : null}
                </>
              )}
              {["dzen", "vkontakte", "youtube"].includes(
                state.platform.tag,
              ) && (
                <>
                  <CardInputWithError
                    isError={error.group_id}
                    disabled={!!channel.data.id && !isAdmin}
                    type="text"
                    name="group"
                    value={state.group_id}
                    onFocus={handleFocus}
                    onChange={({ target }) => {
                      setState({
                        ...state,
                        group_id: target.value,
                      });

                      if (error.group_id) {
                        const message = errors.message;
                        delete message.long.group_id;

                        if (!!Object.keys(message).length) {
                          setError(message);
                        } else resetError();
                      }
                    }}
                  />
                  {error.group_id ? (
                    <CardErrorMessage>
                      {errors.message.long.group_id}
                    </CardErrorMessage>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </CardChild_3>
        <CardChild_4>
          <div>
            <label>
              <span>Контактное лицо *</span>
              <span>(указывается полностью и без сокращений)</span>
            </label>
            <CardInputWithError
              isError={error.contact_name}
              type="text"
              name="name"
              placeholder="Фамилия Имя Отчество"
              value={state.contact_name}
              onFocus={handleFocus}
              onChange={({ target }) =>
                setState({
                  ...state,
                  contact_name: target.value,
                })
              }
            />
          </div>
          <div>
            <label>Почта контактного лица *</label>
            <CardInputWithError
              isError={error.contact_email}
              type="email"
              name="email"
              placeholder="имя@culture.mos.ru"
              value={state.contact_email}
              onFocus={handleFocus}
              onChange={({ target }) => {
                setState({
                  ...state,
                  contact_email: target.value,
                });
              }}
            />
          </div>
          <div>
            <label>Телефон контактного лица *</label>
            <CardInputWithError
              isError={error.contact_phone}
              type="tel"
              name="phone"
              placeholder="+7 (XXX) XXX-XX-XX"
              value={state.contact_phone}
              onFocus={handleFocus}
              onChange={({ target }) =>
                setState({
                  ...state,
                  contact_phone: target.value,
                })
              }
            />
          </div>
        </CardChild_4>
        <CardChild_5>
          <CardActivationStatusAction>
            <label>Статус автоматизации</label>
            {[
              "dzen",
              "odnoklassniki",
              "curated-website",
              "vkontakte",
              "youtube",
            ].includes(state.platform.tag) ? (
              <Button
                secondary={true}
                btn_c={automation.button.btn_c}
                btn_bgc={automation.button.btn_bgc}
                btn_txt={automation.button.text}
                onBtnClick={() => {
                  setState({
                    ...state,
                    is_automated: !isAutomated,
                  });

                  handleFocus();
                }}
              />
            ) : null}
          </CardActivationStatusAction>
          {!isAutomated ? (
            <CardActivationStatusInfo>
              Для каждой соцсети нужно проверить, собирается ли с неё статистика
              автоматически. Если автоматизация не настроена, необходимо сначала
              провести работы по автоматизации по инструкциям. После этого
              необходимо поставить соответствующий статус автоматизации.
            </CardActivationStatusInfo>
          ) : null}
          <CardActivationStatusText
            dangerouslySetInnerHTML={{
              __html: automation.text,
            }}
          />
        </CardChild_5>
        {isAdmin && isDataId && (
          <CardChild_5>
            <Selector
              stateName="automation_check"
              state={state}
              filters={filters.data["automation_check"]}
              onFocus={handleFocus}
              setState={setState}
            />
          </CardChild_5>
        )}
      </div>
      <Icon icon={icons.triangle} />
      <NewCardButtons isEdit={isActive}>
        <Button
          btn_br="3px"
          btn_bgc="initial"
          btn_txt="Сохранить канал"
          onBtnClick={handleSave}
          btn_conf={{ disabled: checkIsDisabled() }}
        />
        <Button
          btn_br="3px"
          btn_bgc="initial"
          btn_txt="Отменить"
          onBtnClick={handleClose}
        />
      </NewCardButtons>
    </CardContainer>
  );
};

const mapStateToProps = ({ filters, errors, channel }) => ({
  filters,
  errors,
  channel,
});

export default connect(mapStateToProps, {
  setError,
  resetError,
  setChannel,
  updateChannel,
  resetChannel,
})(Card);

const CardContainer = styled.div`
  position: relative;
  background: rgb(255, 255, 255);
  width: 100%;

  input,
  textarea {
    outline: none;
  }

  * {
    font-family: Rubik, sans-serif;
  }

  > div:not(:last-child) {
    display: flex;
    flex-direction: column;

    > div {
      input {
        height: 36px;
      }
    }
  }

  > svg {
    position: absolute;
    top: -3px;
    right: -3px;
  }

  [disabled] {
    background: rgba(239, 239, 239, 0.3);
    color: rgb(128, 128, 128);
    opacity: 1;
  }

  ::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -3px;
    right: -3px;
    border-top: 40px solid rgb(237, 238, 233);
    border-left: 40px solid transparent;
  }

  @media (min-width: 701px) {
    padding: 30px 60px 30px 20px;
    border: 3px #24627a solid;

    > div:not(:last-child) {
      row-gap: 30px;

      input,
      label {
        font-size: 18px;
      }

      > div {
        gap: 10px;

        input {
          border-radius: 3px;
          border: 1px solid rgb(158, 157, 157);
          padding: 5.5px 10px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: 20px 15px;
    border: 3px #24627a solid;
    border-left: none;

    > div:not(:last-child) {
      row-gap: 20px;

      input,
      label {
        font-size: 16px;
      }

      > div {
        :not(:nth-child(1)) {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
        }

        input {
          border-radius: 0;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid rgb(158, 157, 157);
          padding: 5.5px;
        }
      }
    }
  }
`;

const CardChild_1 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  > div {
    :nth-child(1) {
      textarea {
        font-weight: 500;
        color: #24627aff;
        border: none;
        padding: 0;
        width: auto;
        resize: none;
      }
    }

    :nth-child(2) {
      > div {
        justify-content: space-between;
      }
    }
  }

  @media (min-width: 701px) {
    > div {
      :nth-child(1) {
        textarea {
          font-size: 24px;
        }
      }

      :nth-child(2) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 10px;
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      :nth-child(1) {
        textarea {
          font-size: 20px;
          padding-right: 30px;
        }
      }

      :nth-child(2) {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }
  }
`;

const CardChild_2 = styled.div`
  > div {
    :nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;
      width: 100%;
    }
  }

  @media (min-width: 701px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;

    > div {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;

      :nth-child(1) {
        flex: 2;
      }

      :nth-child(2) {
        width: 150px;
        white-space: nowrap;
      }
    }
  }
`;

const CardChild_3 = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    :nth-child(2) {
      label {
        display: flex;
        justify-content: space-between;

        > a {
          color: rgb(36, 98, 122);
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
`;

const CardChild_4 = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    white-space: nowrap;
    row-gap: 10px;

    :nth-child(1),
    :nth-child(2) {
      flex: 1;
    }

    :nth-child(3) {
      width: auto;
    }

    label {
      display: flex;
      flex-direction: column;
      row-gap: 3px;

      > span:nth-child(2) {
        font-size: 13px;
      }
    }
  }

  @media (min-width: 701px) {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
`;

const CardChild_5 = styled.div`
  display: flex;

  @media (min-width: 701px) {
    flex-direction: column;
  }
`;

const NewCardButtons = styled.div`
  display: ${({ isEdit }) => (isEdit ? "flex" : "none")};
  margin-top: 30px;
  flex-direction: row;

  @media (hover: hover) and (pointer: fine) {
    button {
      :nth-child(1):not([disabled]):hover {
        background: rgb(36, 98, 122);
        color: rgb(237, 238, 233);
      }

      :nth-child(2):hover {
        background: #922d15;
        color: rgb(237, 238, 233);
      }
    }
  }

  @media (min-width: 701px) {
    column-gap: 30px;
    justify-content: end;
  }

  @media (max-width: 700px) {
    column-gap: 10px;

    > button {
      width: 100%;
    }
  }
`;

const CardInputWithError = styled.input`
  ${({ isError }) =>
    isError &&
    `background-color: rgba(146, 45, 21, 0.1) !important; border: 2px solid #922D15 !important;`}
`;

const CardErrorMessage = styled.div`
  display: block;
  padding: 7.5px 10px;
  font-size: 18px;
  background-color: #922d15;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  z-index: 11;
  bottom: -34px;
  width: 100%;
  color: #edeee9;
`;

const CardActivationStatusInfo = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`;

const CardActivationStatusAction = styled.div`
  display: flex;

  @media (min-width: 701px) {
    align-items: center;
    column-gap: 10px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    row-gap: 10px;

    > button {
      width: 100%;
    }
  }

  @media (hover: hover) {
    > button {
      :hover {
        background-color: #24627a;
        border-color: #24627a;
        color: #edeee9;
      }
    }
  }
`;

const CardActivationStatusText = styled.div`
  padding: 5.5px 10px;
  border: 1px solid rgb(158, 157, 157);
  border-radius: 3px;

  line-height: 23px;
  color: rgb(128, 128, 128);

  a {
    color: rgb(36, 98, 122);
  }

  @media (min-width: 701px) {
    font-size: 18px;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }
`;
