import { combineReducers } from "redux";

import home from "./home";
import container from "./container";
import documents from "./documents";
import page from "./page";
import search from "./search";
import filters from "./filters";
import menu from "./menu";
import addresses from "./addresses";
import channels from "./channels";
import channel from "./channel";
import errors from "./errors";
import progress from "./progress";
import allowance from "./allowance";

export default combineReducers({
  home: home.reducer,
  container: container.reducer,
  documents: documents.reducer,
  page: page.reducer,
  search: search.reducer,
  filters: filters.reducer,
  menu: menu.reducer,
  addresses: addresses.reducer,
  channels: channels.reducer,
  channel: channel.reducer,
  progress: progress.reducer,
  allowance: allowance.reducer,
  errors: errors.reducer,
});
