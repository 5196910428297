import React, { useEffect } from "react";
import styled from "styled-components";
import PlacesGrid from "./PlacesGrid";
import Info from "./Info";
import Separator from "../../components/Separator";
import { connect } from "react-redux";
import { fetchAddresses } from "../../reducers/addresses/actions";

const StyledFooter = styled.footer`
  margin-bottom: 40px;
`;

const FooterSeparator = styled(Separator)`
  @media (min-width: 569px) {
    margin-top: 40px;
  }

  @media (max-width: 568px) {
    margin-top: 35px;
  }
`;

const Footer = ({ data, loading, fetchAddresses }) => {
  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <StyledFooter>
      {loading && <PlacesGrid data={data} />}
      <FooterSeparator />
      <Info />
    </StyledFooter>
  );
};

const mapStateToProps = ({ addresses: { data, loading } }) => ({
  data,
  loading,
});
export default connect(mapStateToProps, { fetchAddresses })(Footer);
