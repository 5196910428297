import axios from "axios";

const callApi = async (endPoint, json = null) => {
  const url = process.env.REACT_APP_API_URL;

  const params = {
    url,
    method: "get",
  };

  if (json) {
    params.method = "post";
    params.data = json;
  }

  try {
    return await axios(params);
  } catch (error) {
    return error;
  }
};

const fetchSearchApi = (query) => {
  return callApi(`api`, { query });
};

const fetchAddresses = async () => {
  const url = `${process.env.REACT_APP_ADMIN_API}/addresses`;
  const params = { method: "get", url };

  try {
    return await axios(params);
  } catch (error) {
    return error;
  }
};

const fetchFilters = async (checkTokenExpiredYesUpdate) => {
  const url = `${process.env.REACT_APP_CHANNELS_API}/filters`;
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const params = {
    headers: {
      Authorization: `Bearer ${TOKEN_KEY}`,
      "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
      "Content-Type": `application/json`,
    },
    method: "get",
  };

  try {
    return await axios(url, params);
  } catch (error) {
    return error;
  }
};

const fetchChannels = async (filters, pages, checkTokenExpiredYesUpdate) => {
  const url = `${process.env.REACT_APP_CHANNELS_API}/channel`;
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const params = { filters, page: pages.page };

  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN_KEY}`,
      "Accept-Language": "ru",
      "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
      "Content-Type": `application/json`,
    },
    method: "get",
    params,
  };

  try {
    return await axios(url, config);
  } catch (error) {
    return error;
  }
};

const patchChannel = async (data, checkTokenExpiredYesUpdate) => {
  const url = `${process.env.REACT_APP_CHANNELS_API}/channel`;
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN_KEY}`,
      "Accept-Language": "ru",
      "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
      "Content-Type": `application/json`,
    },
    method: "patch",
    data: { data },
  };

  try {
    return await axios(url, config);
  } catch (error) {
    return error;
  }
};

const putChannel = async (data, checkTokenExpiredYesUpdate) => {
  const url = `${process.env.REACT_APP_CHANNELS_API}/channel`;
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const config = {
    headers: {
      Authorization: `Bearer ${TOKEN_KEY}`,
      "Accept-Language": "ru",
      "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
      "Content-Type": `application/json`,
    },
    method: "put",
    data: { data },
  };

  try {
    return await axios(url, config);
  } catch (error) {
    return error;
  }
};

export default {
  fetchSearchApi,
  fetchAddresses,
  fetchFilters,
  fetchChannels,
  patchChannel,
  putChannel,
};
