import * as types from "./actionTypes";

export const setSearchConfig = (config) => ({
  type: types.SET_SEARCH_CONFIG,
  config,
});

export const fetchSearch = () => ({
  type: types.SEARCH_FETCH,
});

export const fetchSearchSuccess = (payload) => ({
  type: types.SEARCH_FETCH_SUCCESS,
  payload,
});

export const fetchSearchNext = () => ({
  type: types.SEARCH_FETCH_NEXT,
});

export const fetchSearchNextSuccess = (payload) => ({
  type: types.SEARCH_FETCH_NEXT_SUCCESS,
  payload,
});

export const resetSearch = () => ({
  type: types.SEARCH_RESET,
});
