import React from "react";
import styled from "styled-components";
import Section from "../Section";
import { connect } from "react-redux";

const Subjects = styled(Section)`
  @media (max-width: 1150px) {
    ::before {
      display: block;
      content: "";
      height: 3px;
      width: 100%;
      background-color: #24627a;
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  * {
    color: #24627a;
  }
`;

const SubjectsTitle = styled.h3`
  font-family: Rubik, sans-serif;
  font-weight: 500;
  margin: 0;

  @media (min-width: 769px) {
    font-size: 48px;
    line-height: 57px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const NavTitle = ({ name }) => {
  return (
    name && (
      <Subjects>
        <SubjectsTitle>{name}</SubjectsTitle>
      </Subjects>
    )
  );
};

const mapStateToProps = ({
  container: {
    config: { name },
  },
}) => ({
  name,
});

export default connect(mapStateToProps)(NavTitle);
