import React from "react";

export default function (props) {
  return (
    <svg
      width="25"
      height="70"
      viewBox="0 0 25 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.999 47.4867V47.4859H9.56737V44.5093H14.6528V44.7099C14.6528 45.0377 14.9144 45.3046 15.2391 45.3046C15.5612 45.3046 15.8241 45.0377 15.8241 44.7099V43.6186C15.8241 43.4538 15.6939 43.32 15.5307 43.32H8.98237C8.64487 43.32 8.39738 43.5961 8.39429 43.9146V52.246C8.39429 52.5743 8.65679 52.8407 8.98237 52.8407V52.8416H13.999V52.8407C15.4425 52.823 16.6063 51.6319 16.6063 50.165C16.6063 48.6964 15.4425 47.5035 13.999 47.4867ZM13.999 51.6495H9.56737V48.6757H13.999C14.7944 48.6933 15.4328 49.3547 15.4328 50.165C15.4328 50.974 14.7944 51.6319 13.999 51.6495Z"
        fill={props.fill || "#222220"}
      />
      <path
        d="M22.6194 -39.9999V65.8864L12.4998 60.0204L2.38102 65.8855V-39.9999H0V69.025C0 69.318 0.237352 69.5557 0.532497 69.5557C0.625144 69.5557 0.710731 69.5309 0.787936 69.4881L0.793231 69.4907L12.4998 62.7031L24.2054 69.4889L24.2116 69.4863C24.2879 69.5309 24.3753 69.5557 24.4679 69.5557C24.7626 69.5557 25 69.318 25 69.025V-39.9999H22.6194Z"
        fill={props.fill || "#222220"}
      />
    </svg>
  );
}
