import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import styled from "styled-components";

import objectToUrlConstructor from "../../../helpers/objectToUrlConstructor";
import Button from "../../../components/Button";
import { icons } from "../../../components/Icon";
import { connect } from "react-redux";
import { setParsedFilters } from "../../../reducers/filters/actions";

const Selected = ({ data, className }) => {
  const [selected, setSelected] = useState({});
  const { search, pathname } = useLocation();
  const history = useHistory();
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const removePropertiesByKey = (name, m) => {
    const temp = { ...params };

    if (name === "names") {
      temp[name] = temp[name].split(",").filter((f) => f !== selected[name][m]);
    } else {
      temp[name] = temp[name].split(",").filter((f) => f !== m);
    }

    temp[name] = !!temp[name].length ? temp[name] : undefined;

    let url = objectToUrlConstructor(temp);
    const isUrl = !!url;
    url = isUrl ? `${pathname}?${url}` : `${pathname}`;

    history.replace(url);
  };

  const setObject = () => {
    let temp = {};
    let name = {};

    if (!!params) {
      Object.keys(params).forEach((key) => {
        const isIncludesKey = [
          "title",
          "group_id",
          "tracker",
          "contact_email",
          "url",
        ].includes(key);

        if (isIncludesKey) return;

        if (!!params[key]) {
          params[key].split(",").forEach((b) => {
            if (key === "names") {
              name = { [data[key].findIndex((f) => f === b)]: b };
            } else if (key === "status") {
              name = { [b]: data[key][b] };
            } else {
              name = { [b]: data[key].find((f) => f.id === b).name };
            }

            temp = {
              ...temp,
              [key]: { ...temp[key], ...name },
            };
          });
        }
      });
    }

    setSelected(temp);
  };

  useEffect(() => {
    setObject();
  }, [search]);

  return Object.keys(selected).length ? (
    <Container className={className}>
      <div>
        {Object.keys(selected).map((btn) => {
          return Object.keys(selected[btn]).map((m, i) => {
            return (
              <Button
                key={i}
                secondary="true"
                btn_c="#9E9D9D"
                btn_bc="#9E9D9D"
                btn_bgc="#FFFFFF"
                btn_txt={selected[btn][m]}
                icon={icons.close_x}
                icon_fill="#9E9D9D"
                icon_height="10"
                icon_width="10"
                onBtnClick={() => removePropertiesByKey(btn, m)}
              />
            );
          });
        })}
      </div>
    </Container>
  ) : null;
};

const mapStateToProps = ({ filters: { data, parsed } }) => ({
  data,
  parsed,
});

export default connect(mapStateToProps, { setParsedFilters })(Selected);

const Container = styled.div`
  > div {
    display: flex;

    overflow: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: 431px) {
    background: #ffffff;

    > div {
      gap: 10px;
      flex-wrap: wrap;
      max-width: 1280px;
      margin: 0 auto;
      width: 100%;
      padding: 0 30px 25px;
    }
  }

  @media (max-width: 430px) {
    > div {
      column-gap: 10px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
