import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Pages from "../../components/Pages";
import {
  fetchHome,
  resetHome,
  fetchHomeNext,
} from "../../reducers/home/actions";
import PagesLoadMore from "../../components/PagesLoadMore";

class Home extends Component {
  constructor(props) {
    super(props);

    props.fetchHome();
  }

  componentWillUnmount() {
    this.props.resetHome();
  }

  render() {
    let { isHomeLoaded, data, offset, total, fetchHomeNext } = this.props;

    return (
      <Fragment>
        {isHomeLoaded && <Pages data={data} />}
        {offset < total && <PagesLoadMore onBtnClick={fetchHomeNext} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ home: { data, offset, total, isHomeLoaded } }) => ({
  data,
  offset,
  total,
  isHomeLoaded,
});

export default connect(mapStateToProps, {
  fetchHome,
  resetHome,
  fetchHomeNext,
})(Home);
