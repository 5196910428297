import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { activation, status } from "../bd";
import { setError, resetError } from "../../../reducers/errors/actions";
import {
  setChannel,
  updateChannel,
  resetChannel,
} from "../../../reducers/channel/actions";
import Selector from "../Selector";

const Row = ({
  className,
  isAdmin,
  filters,
  data,
  channel,
  errors,
  setError,
  resetError,
  setChannel,
  updateChannel,
  resetChannel,
  index,
  isTableScrolling,
}) => {
  let error = {};
  let automation = {};
  let isDataId = false;
  let isAutomated = false;
  let isActive = false;
  let isErrorInActive = false;

  const [state, setState] = useState(null);
  const [isLoaded, setLoaded] = useState(false);

  if (isLoaded) {
    isDataId = !!state.id;
    isAutomated = state.is_automated;
    isActive = channel.data.id === data.id;
    isErrorInActive = isActive && errors.isError;

    automation = {
      text: isAutomated
        ? status[state.platform.tag]
        : activation[state.platform.tag] || "Выберите платформу",
      button: {
        text: filters.data.status[isAutomated ? 1 : 0],
        btn_c: isAutomated ? "#EDEEE9" : "#9E9D9D",
        btn_bgc: isAutomated ? "#24627A" : "initial",
      },
    };

    if (isErrorInActive) {
      error = {
        url: !!errors.message.long.url,
        group_id: !!errors.message.long.group_id,
        tracker: !!errors.message.long.tracker,
        contact_name: !!errors.message.long.contact_name,
        contact_email: !!errors.message.long.contact_email,
        contact_phone: !!errors.message.long.contact_phone,
      };
    }
  }

  const automationCheckColor = {
    0: "248,238,215",
    1: "213,147,148",
    2: "255,255,255",
  };

  const handleFocus = () => {
    setChannel(data);

    if (!channel.isChannel) resetChannel();
  };

  const checkIsDisabled = () => {
    let isNotGroupIdIfPlatform = false;

    const isNotSomeData = [
      state.url,
      state.contact_name,
      state.contact_email,
      state.contact_phone,
      state.title,
      state.platform.id,
      state.library.id,
    ].some((s) => !s);

    if (["dzen", "vkontakte", "youtube"].includes(state.platform.tag)) {
      isNotGroupIdIfPlatform = !state.group_id && !!state.platform.id;
    } else if (state.platform.tag === "curated-website") {
      isNotGroupIdIfPlatform = !state.tracker && !!state.platform.id;
    }

    return isNotSomeData || isNotGroupIdIfPlatform;
  };

  const handleSave = () => {
    // if (!checkIsDisabled()) {
    let temp = { ...state };

    console.log("❗", state);
    temp.library_id = temp.library.id;
    temp.platform_id = temp.platform.id;

    temp.departments_copy = temp.departments;
    temp.departments = temp.departments.map((m) => m.id);

    resetError();
    setChannel(temp);
    updateChannel();
    // }
  };

  useEffect(() => {
    setState(data);
    setLoaded(true);
  }, []);

  return (
    isLoaded && (
      <RowContainer
        className={className}
        isTableScrolling={isTableScrolling}
        automationCheckColor={
          !state.is_relevant
            ? "158,157,157"
            : automationCheckColor[state.automation_check]
        }
      >
        <td>{index}</td>
        <td>
          <input
            size={1 + state.title.length}
            name="title"
            value={state.title}
            onFocus={handleFocus}
            onBlur={handleSave}
            onChange={({ target }) => {
              setState({
                ...state,
                title: target.value,
              });
            }}
          />
        </td>
        <td>
          <RowSelectorLong
            stateName="library"
            state={state}
            filters={filters.data["libraries"]}
            isDisabled={channel.isChannel ? !isAdmin : !channel.isChannel}
            onFocus={handleFocus}
            setState={setState}
            onBlur={handleSave}
          />
        </td>
        <td>
          <RowSelectorLongDepartment
            stateName="departments"
            state={state}
            filters={
              !!state.library.id
                ? filters.data["departments"].filter(
                    (f) => f.library_id === state.library.id,
                  )
                : []
            }
            onFocus={handleFocus}
            setState={setState}
            onBlur={handleSave}
          />
        </td>
        <td>
          <RowInputWithError
            disabled={!!channel.data.id && !isAdmin}
            isError={error.url}
            type="text"
            name="url"
            value={state.url}
            size={1 + state.url.length}
            onFocus={handleFocus}
            onBlur={handleSave}
            onChange={({ target }) => {
              setState({
                ...state,
                url: target.value,
              });

              if (error.url) {
                const message = errors.message;
                delete message.long.url;

                if (!!Object.keys(message).length) {
                  setError(message);
                } else resetError();
              }
            }}
          />
          {error.url ? (
            <RowErrorMessage>{errors.message.long.url}</RowErrorMessage>
          ) : null}
        </td>
        <td>
          <RowSelectorIsRelevant
            stateName="is_relevant"
            state={state}
            filters={filters.data["relevancy"]}
            onFocus={handleFocus}
            setState={setState}
            onBlur={handleSave}
          />
        </td>
        <td>
          <RowSelectorPlatform
            stateName="platform"
            state={state}
            filters={filters.data["platforms"]}
            isDisabled={isDataId}
            onFocus={handleFocus}
            setState={setState}
            onBlur={handleSave}
          />
        </td>
        <RowGroupId>
          {["dzen", "vkontakte", "youtube"].includes(state.platform.tag) ? (
            <>
              <RowInputWithError
                isError={error.group_id}
                disabled={!!channel.data.id && !isAdmin}
                type="text"
                name="group"
                value={state.group_id}
                onFocus={handleFocus}
                onBlur={handleSave}
                onChange={({ target }) => {
                  setState({
                    ...state,
                    group_id: target.value,
                  });

                  if (error.group_id) {
                    const message = errors.message;
                    delete message.long.group_id;

                    if (!!Object.keys(message).length) {
                      setError(message);
                    } else resetError();
                  }
                }}
              />
              <label>
                {["vkontakte"].includes(state.platform.tag) && (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_URL +
                      "/help/Как+узнать+ID+страницы+или+группы+в+VK.pdf"
                    }
                  >
                    Как&nbsp;узнать?
                  </a>
                )}
                {["dzen"].includes(state.platform.tag) && (
                  <a
                    target="_blank"
                    href={
                      process.env.REACT_APP_URL +
                      `/help/Как+узнать+ID+группы+в+Дзен.pdf`
                    }
                  >
                    Как&nbsp;узнать?
                  </a>
                )}
              </label>
              {error.group_id ? (
                <RowErrorMessage>
                  {errors.message.long.group_id}
                </RowErrorMessage>
              ) : null}
            </>
          ) : null}
          {["curated-website"].includes(state.platform.tag) ? (
            <>
              <RowInputWithError
                isError={error.tracker}
                disabled={!!channel.data.id && !isAdmin}
                type="text"
                name="group"
                value={state.tracker}
                onFocus={handleFocus}
                onBlur={handleSave}
                onChange={({ target }) => {
                  setState({
                    ...state,
                    tracker: target.value,
                  });

                  if (error.tracker) {
                    const message = errors.message;
                    delete message.long.tracker;

                    if (!!Object.keys(message).length) {
                      setError(message);
                    } else resetError();
                  }
                }}
              />
              {error.tracker ? (
                <RowErrorMessage>{errors.message.long.tracker}</RowErrorMessage>
              ) : null}
            </>
          ) : null}
        </RowGroupId>
        <td>
          <RowInputWithError
            as="textarea"
            isError={error.contact_name}
            type="text"
            name="name"
            placeholder="Фамилия Имя Отчество"
            value={state.contact_name}
            onFocus={handleFocus}
            onBlur={handleSave}
            onChange={({ target }) => {
              setState({
                ...state,
                contact_name: target.value,
              });

              target.setAttribute("style", `height: ${target.scrollHeight}px;`);
            }}
          />
        </td>
        <td>
          <RowInputWithError
            isError={error.contact_email}
            size={1 + state.contact_email.length}
            type="email"
            name="email"
            placeholder="имя@culture.mos.ru"
            value={state.contact_email}
            onFocus={handleFocus}
            onBlur={handleSave}
            onChange={({ target }) => {
              setState({
                ...state,
                contact_email: target.value,
              });
            }}
          />
        </td>
        <td>
          <RowInputWithError
            isError={error.contact_phone}
            size={1 + state.contact_phone.length}
            type="tel"
            name="phone"
            placeholder="+7 (XXX) XXX-XX-XX"
            value={state.contact_phone}
            onFocus={handleFocus}
            onBlur={handleSave}
            onChange={({ target }) => {
              setState({
                ...state,
                contact_phone: target.value,
              });
            }}
          />
        </td>
        <td>
          <RowActivationStatusAction>
            {[
              "dzen",
              "odnoklassniki",
              "curated-website",
              "vkontakte",
              "youtube",
            ].includes(state.platform.tag) ? (
              <RowSelectorIsAutomated
                stateName="is_automated"
                state={state}
                filters={filters.data["status"]}
                onFocus={handleFocus}
                setState={setState}
                onBlur={handleSave}
              />
            ) : null}
          </RowActivationStatusAction>
          {!isAutomated ? (
            <RowActivationStatusInfo>
              Для каждой соцсети нужно проверить, собирается ли с неё статистика
              автоматически. Если автоматизация не настроена, необходимо сначала
              провести работы по автоматизации по инструкциям. После этого
              необходимо поставить соответствующий статус автоматизации.
            </RowActivationStatusInfo>
          ) : null}
          <RowActivationStatusText
            dangerouslySetInnerHTML={{
              __html: automation.text,
            }}
          />
        </td>
        {isAdmin && (
          <td>
            <RowSelector
              stateName="automation_check"
              state={state}
              filters={filters.data["automation_check"]}
              onFocus={handleFocus}
              setState={setState}
              onBlur={handleSave}
            />
          </td>
        )}
      </RowContainer>
    )
  );
};

const mapStateToProps = ({ filters, errors, channel }) => ({
  filters,
  errors,
  channel,
});

export default connect(mapStateToProps, {
  setError,
  resetError,
  setChannel,
  updateChannel,
  resetChannel,
})(Row);

const RowActivationStatusInfo = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`;

const RowSelector = styled(Selector)`
  & > div div:nth-child(1) {
    height: 100%;
    border: none;
    padding: 0;
    column-gap: 10px;
  }

  & > div div:nth-child(2) {
    top: auto;
    margin-top: 10px;
  }
`;

const RowSelectorIsRelevant = styled(RowSelector)`
  width: 120px;
`;

const RowSelectorPlatform = styled(RowSelector)`
  width: 130px;
`;

const RowSelectorLong = styled(RowSelector)`
  min-width: 300px;

  & > div div:nth-child(1) {
    > span {
      white-space: normal;
    }
  }
`;

const RowSelectorLongDepartment = styled(RowSelectorLong)`
  & > div {
    div {
      :nth-child(2) {
        position: initial;
        width: auto;

        > span {
          white-space: nowrap;
        }
      }
    }
  }
`;

const RowSelectorIsAutomated = styled(RowSelector)`
  width: 100%;
  margin-bottom: 10px;

  span {
    white-space: nowrap;
  }
`;

const RowGroupId = styled.td`
  > label {
    a {
      color: rgb(36, 98, 122);
    }
  }

  > input {
    margin-bottom: 10px;
  }
`;

const RowContainer = styled.tr`
  position: relative;

  td {
    :nth-child(2) {
      background-color: rgba(
        ${({ isTableScrolling, automationCheckColor }) =>
          isTableScrolling
            ? automationCheckColor
            : automationCheckColor + ", 0.5"}
      );
    }

    :not(:nth-child(2)) {
      background-color: rgba(
        ${({ automationCheckColor }) => automationCheckColor},
        0.5
      );
    }
  }

  * {
    background: initial;
    color: #222222;
    font-family: Rubik, sans-serif;
    font-size: 13px !important;
    line-height: 22px;

    :focus-visible {
      border: none;
    }
  }

  input,
  textarea {
    outline: none;
    border: none;
    height: 100%;
    padding: 0;
    width: 100%;
  }

  textarea {
    resize: none;
  }

  td {
    :first-child {
      text-align: center;
    }
  }
`;

const RowInputWithError = styled.input`
  ${({ isError }) =>
    isError &&
    `background-color: rgba(146, 45, 21, 0.1) !important; border: 2px solid #922D15 !important;`}
`;

const RowErrorMessage = styled.div`
  display: block;
  padding: 7.5px 10px;
  font-size: 18px;
  background-color: #922d15;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  z-index: 11;
  bottom: -34px;
  width: 100%;
  color: #edeee9;
`;

const RowActivationStatusAction = styled.div`
  display: flex;

  @media (min-width: 431px) {
    align-items: center;
    column-gap: 10px;
  }

  @media (hover: hover) {
    > button {
      :hover {
        background-color: #24627a;
        border-color: #24627a;
        color: #edeee9;
      }
    }
  }
`;

const RowActivationStatusText = styled.div`
  line-height: 23px;
  color: rgb(128, 128, 128);

  a {
    color: rgb(36, 98, 122);
  }
`;
