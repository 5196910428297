import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Section from "../../components/Section";
import { setFilters } from "../../reducers/filters/actions";

const Filters = ({ setFilters }) => {
  const filters = {
    theme: "Тема",
    type: "Тип материала",
    year: "Год публикации",
  };

  return (
    <FiltersSection>
      <FiltersContainer>
        {Object.keys(filters).map((item, i) => {
          return (
            <FilterBtn
              key={i}
              onClick={() => setFilters({ name: item, filter: filters[item] })}
            >{`+ ${filters[item]}`}</FilterBtn>
          );
        })}
      </FiltersContainer>
    </FiltersSection>
  );
};

export default connect(null, {
  setFilters,
})(Filters);

const FiltersSection = styled(Section)`
  @media (min-width: 1151px) {
    ::before {
      display: block;
      content: "";
      height: 3px;
      width: 100%;
      background-color: #24627a;
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 568px) {
    padding: 0;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  margin-top: 25px;

  @media (min-width: 1151px) {
    margin-bottom: 30px;
  }

  @media (max-width: 568px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const CardButton = styled.button`
  display: flex;
  height: fit-content;
  width: fit-content;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
`;

const FilterBtn = styled(CardButton)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  background-color: initial;

  @media (min-width: 1151px) {
    border: none;
    padding-right: 0;
    padding-left: 0;
    color: #24627a;

    :not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 1150px) {
    border: 1px solid #9e9d9d;
    border-radius: 20px;
    padding: 6px 15px;
    color: #9e9d9d;

    :not(:last-child) {
      margin-right: 10px;
    }
  }

  @media (max-width: 568px) {
    :first-child {
      margin-left: 15px;
    }

    :not(:last-child) {
      margin-right: 5px;
    }
  }
`;
