import React from "react";

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.48 124.48">
    <g id="oracal">
      <path
        className="cls-1"
        d="M62.24,0a62.24,62.24,0,1,0,62.24,62.24A62.25,62.25,0,0,0,62.24,0ZM101.3,77.8A10.12,10.12,0,0,1,91.17,87.92H33.3A10.12,10.12,0,0,1,23.18,77.8V44.05A10.13,10.13,0,0,1,33.3,33.93H91.17A10.13,10.13,0,0,1,101.3,44.05Z"
        fill={props.fill}
      />
      <path
        className="cls-1"
        d="M74.07,59.54,56.76,51.13a1.5,1.5,0,0,0-1.45.07,1.52,1.52,0,0,0-.71,1.28V69.3a1.51,1.51,0,0,0,1.5,1.5,1.52,1.52,0,0,0,.66-.15l17.31-8.41a1.5,1.5,0,0,0,0-2.7Z"
        fill={props.fill}
      />
    </g>
  </svg>
);
