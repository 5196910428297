import React from "react";

export default (props) => (
  <svg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 0C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5 30H25C27.7614 30 30 27.7614 30 25V5C30 2.23858 27.7614 0 25 0H5ZM20 8.75H17.5962C16.8262 8.75 16.25 9.065 16.25 9.86125V11.25H20L19.7013 15H16.25V25H12.5V15H10V11.25H12.5V8.84625C12.5 6.31875 13.83 5 16.8263 5H20V8.75Z"
      fill={props.fill}
    />
  </svg>
);
