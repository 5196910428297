import React from "react";
import styled from "styled-components";
import BtnPrimary from "../BtnPrimary";

export default function ({ data }) {
  return (
    <PageRect
      as="a"
      href={data.btn.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PageRectText>{data.text}</PageRectText>
      <PageRectBtn>{data.btn.text}</PageRectBtn>
    </PageRect>
  );
}

const PageRect = styled.div`
  border: 1px solid #24627a;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
`;

const PageRectText = styled.p`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;

  color: #24627a;

  @media (min-width: 569px) {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }

  @media (max-width: 568px) {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 15px 0;
  }
`;

const PageRectBtn = styled(BtnPrimary)`
  display: flex;
  align-items: center;
  background-color: #24627a;
  border-color: #24627a;
  color: #edeee9;
  cursor: pointer;
  padding: 0 15px;

  @media (min-width: 769px) {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    width: fit-content;
  }

  @media (max-width: 768px) {
    height: 33px;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    justify-content: center;
  }
`;
