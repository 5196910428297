import * as types from "./actionTypes";

const initialState = {
  data: [],
  config: { query: "", theme: "", part: "", type: "" },
  limit: 6,
  total: 0,
  offset: 1,
  isSearchLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_CONFIG:
      return {
        ...state,
        config: action.config,
      };

    case types.SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        isSearchLoaded: true,
      };

    case types.SEARCH_FETCH_NEXT_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        offset: state.offset + 1,
      };

    case types.SEARCH_RESET:
      return {
        data: [],
        config: { query: "", theme: "", part: "", type: "" },
        limit: 6,
        total: 0,
        offset: 1,
        isSearchLoaded: false,
      };

    default:
      return state;
  }
};
