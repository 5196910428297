import React from "react";

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.48 124.48">
    <g id="oracal">
      <path
        className="cls-1"
        d="M62.24,0a62.24,62.24,0,1,0,62.24,62.24A62.25,62.25,0,0,0,62.24,0Zm37.92,88.58h-9c-3.39,0-4.44-2.7-10.54-8.88-5.3-5.14-7.65-5.84-9-5.84-1.83,0-2.35.53-2.35,3V85c0,2.17-.69,3.47-6.45,3.47-9.5,0-20-5.75-27.44-16.45C24.31,56.36,21.27,44.6,21.27,42.17c0-1.31.52-2.53,3-2.53h9c2.27,0,3.13,1,4,3.49,4.44,12.79,11.84,24,14.89,24,1.13,0,1.66-.52,1.66-3.4V50.53c-.36-6.1-3.59-6.63-3.59-8.8a2.17,2.17,0,0,1,2.28-2.09h14.1c1.92,0,2.61,1,2.61,3.31V60.8c0,1.92.88,2.61,1.41,2.61,1.13,0,2.08-.69,4.17-2.78,6.45-7.24,11.06-18.38,11.06-18.38a4,4,0,0,1,3.92-2.52h9c2.7,0,3.31,1.39,2.7,3.31-1.14,5.23-12.11,20.72-12.11,20.72-1,1.57-1.31,2.26,0,4,1,1.3,4.1,4,6.18,6.44,3.84,4.35,6.79,8,7.58,10.54S102.68,88.58,100.16,88.58Z"
        fill={props.fill}
      />
    </g>
  </svg>
);
