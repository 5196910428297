import * as types from "./actionTypes";

export const fetchContainer = (config) => ({
  type: types.CONTAINER_FETCH,
  config,
});

export const fetchContainerSuccess = (payload) => ({
  type: types.CONTAINER_FETCH_SUCCESS,
  payload,
});

export const fetchContainerNext = () => ({
  type: types.CONTAINER_FETCH_NEXT,
});

export const fetchContainerNextSuccess = (payload) => ({
  type: types.CONTAINER_FETCH_NEXT_SUCCESS,
  payload,
});

export const resetContainer = () => ({
  type: types.CONTAINER_RESET,
});
