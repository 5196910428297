import * as types from "./actionTypes";

export const fetchPage = (id) => ({
  type: types.PAGE_FETCH,
  id,
});

export const fetchPageSuccess = (payload) => ({
  type: types.PAGE_FETCH_SUCCESS,
  payload,
});

export const resetPage = () => ({
  type: types.PAGE_RESET,
});
