import React from "react";
import styled from "styled-components";

const InfoRightGrid = styled.div`
  @media (min-width: 569px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 769px) {
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }

  @media (max-width: 768px) and (min-width: 569px) {
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }

  @media (max-width: 568px) {
    display: flex;
    flex-direction: column;
  }
`;

const GridItem = styled.div`
  > * {
    display: flex;
    align-items: center;
  }
`;

const InfoGridItem = styled(GridItem)`
  div,
  a {
    font-family: Rubik, sans-serif;
    font-style: normal;
    line-height: 19px;
  }

  div:first-child {
    font-weight: 500;
    color: #24627a;
    padding-bottom: 7px;
  }

  a {
    color: #222222;
    font-weight: normal;
    padding: 4px 0;
  }

  @media (min-width: 569px) {
    div,
    a {
      font-size: 14px;
    }
  }

  @media (max-width: 568px) {
    div,
    a {
      font-size: 11px;
    }
  }
`;

export default () => {
  const lang = null;

  return (
    <InfoRightGrid>
      <InfoGridItem>
        <div>{lang ? "ABOUT" : "О Библиотеке"}</div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/collection"}
        >
          {lang ? "Stocks" : "Фонды"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/history"}
        >
          {lang ? "History" : "История"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/nekrasov"}
        >
          {lang ? "Nikolay A. Nekrasov" : "Николай Алексеевич Некрасов"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/coworking"}
        >
          {lang ? "Library Space" : "Пространства библиотеки"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/services"}
        >
          {lang ? "Services" : "Услуги"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/management"}
        >
          {lang ? "Top Management" : "Руководство"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/organization"}
        >
          {lang ? "Documents" : "Документы"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/education/structure"}
        >
          {lang ? "" : "Структура"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://bus.gov.ru/pub/independentRating/list"}
        >
          {lang ? "Quality of Service Assessment" : "Оценка качества услуг"}{" "}
          (bus.gov.ru)
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://organizations.kultura.mos.ru/organizations/"}
        >
          {lang ? "Quality of Service Assessment" : "Оценка качества услуг"}{" "}
          (mos.ru)
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/contacts"}
        >
          {lang ? "Contact us" : "Как с нами связаться"}
        </a>
      </InfoGridItem>
      <InfoGridItem>
        <div>{lang ? "TO VISITORS" : "Посетителям"}</div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/rules"}
        >
          {lang ? "Library Rules" : "Правила библиотеки"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/account"}
        >
          {lang ? "Personal Account" : "Личный кабинет"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/resource"}
        >
          {lang ? "E-resources" : "Электронные ресурсы"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/library/library-coworking"}
        >
          {lang
            ? "Spaces to Read and Work"
            : "Пространства для чтения и работы"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/libraryservice"}
        >
          {lang ? "Bibliography" : "Библиография"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/events"}
        >
          {lang ? "Events" : "Культурные события"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/clubs/languageclubs"}
        >
          {lang ? "Language Clubs" : "Языковые клубы"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/excursion"}
        >
          {lang
            ? "Rare Collections Tours"
            : "Экскурсии по фонду редких изданий и коллекций"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/excursion/excursions-moscow"}
        >
          {lang
            ? "Walking Tours"
            : "Пешеходные прогулки «По переулкам Немецкой слободы»"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"https://nekrasovka.ru/articles/guest/faq"}
        >
          {lang ? "Q&A" : "Ответы на вопросы"}
        </a>
      </InfoGridItem>
      <InfoGridItem>
        <div>{lang ? "TO COLLEAGUES" : "Коллегам"}</div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://pro.nekrasovka.ru/parts/1"
        >
          {lang ? "Guidance Papers" : "Методические материалы"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://pro.nekrasovka.ru/parts/2"
        >
          {lang ? "Publications" : "Публикации"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://pro.nekrasovka.ru/parts/3"
        >
          {lang ? "Professional Events" : "События"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://pro.nekrasovka.ru/parts/6"
        >
          {lang ? "Podcasts" : "Подкасты"}
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://pro.nekrasovka.ru/parts/4"
        >
          {lang ? "Educational Programs" : "Образовательные программы"}
        </a>
      </InfoGridItem>
    </InfoRightGrid>
  );
};
