import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import useWindowDimensions from "../../helpers/useWindowDimensions";

const ModalOverlay = styled.div`
  display: flex;

  background-color: rgba(158, 157, 157, 0.9);

  width: 100vw;
  height: 100vh;
`;

const Modal = styled.div`
  visibility: ${({ isFiltersOpen, isMenuOpen }) =>
    isFiltersOpen || isMenuOpen ? "visible" : "hidden"};

  z-index: 2000;
  position: fixed;
  left: 0;

  @media (min-width: 569px) {
    top: 0;
  }

  @media (max-width: 568px) {
    top: 60px;
  }
`;

export default ({ children, isMenuOpen, isFiltersOpen, filterClose }) => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    const root = document.getElementById("root");

    if (isMenuOpen || (isFiltersOpen && width < 569)) {
      root.setAttribute("style", "position: fixed; width: 100%;");
    } else {
      root.removeAttribute("style");
    }
  }, [isMenuOpen, isFiltersOpen]);

  const handleClose = () => {
    if (isFiltersOpen) filterClose();
  };

  return typeof window !== "undefined"
    ? createPortal(
        <Modal isMenuOpen={isMenuOpen} isFiltersOpen={isFiltersOpen}>
          <ModalOverlay onClick={handleClose} />
          {children}
        </Modal>,
        document.getElementById("modal")
      )
    : null;
};
