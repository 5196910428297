import * as types from "./actionTypes";

const initialState = {
  name: "",
  filter: "",
  isFiltersOpen: false,
  loading: false,
  data: {},
  parsed: {},
  pages: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PARSED_FILTERS:
      return {
        ...state,
        parsed: action.payload,
      };
    case types.SET_PAGES_FILTERS:
      return {
        ...state,
        pages: action.payload,
      };
    case types.SET_FILTERS:
      return {
        ...state,
        name: action.payload.name,
        filter: action.payload.filter,
        isFiltersOpen: true,
      };
    case types.GET_FILTERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: true,
      };

    case types.RESET_FILTER:
      return {
        ...state,
        name: "",
        filter: "",
        isFiltersOpen: false,
      };

    case types.RESET_FILTERS:
      return initialState;

    default:
      return state;
  }
};
