import React from "react";
import styled from "styled-components";

import Rectangle from "../Rectangle";
import BtnPart from "../BtnPart";
import Icon, { icons } from "../Icon";

const Card = styled(Rectangle)`
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;

  svg {
    position: absolute;
    top: -3px;
    right: -3px;
  }
`;

const CardText = styled.div`
  position: absolute;
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #24627a;
  padding-right: 40px;
  padding-top: 20px;

  @media (min-width: 769px) {
    padding-left: 20px;

    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 768px) {
    padding-left: 15px;

    font-size: 18px;
    line-height: 21px;
  }
`;

const CardImage = styled.img`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const CardBtnPart = styled(BtnPart)`
  z-index: 1;
  position: absolute;
  padding: 0 10px;

  border-color: ${({ colorTheme }) => colorTheme.third};
  color: ${({ colorTheme }) => colorTheme.third};

  @media (min-width: 769px) {
    height: 26px;

    bottom: 20px;
    left: 20px;
  }

  @media (max-width: 768px) {
    height: 24px;

    bottom: 15px;
    left: 15px;
  }

  @media (hover: hover) and (pointer: fine) {
    :not(.card-btn-part_disabled):hover {
      background-color: ${({ colorTheme }) => colorTheme.primary};
      border-color: ${({ colorTheme }) => colorTheme.primary};
      color: ${({ colorTheme }) => colorTheme.secondary};
    }
  }
`;

const Page = ({
  pageProps: {
    pageId,
    title,
    partName,
    partPath,
    imageUrl,
    onPageClick,
    colorIfImageUrlOrNot,
  },
}) => {
  const cardBtnPartProps = {
    colorTheme: colorIfImageUrlOrNot,
    ...(!!partPath
      ? {
          as: "a",
          href: partPath,
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : { className: "card-btn-part_disabled" }),
  };

  return (
    <Card onClick={(e) => onPageClick(e, pageId)}>
      <CardText>{title}</CardText>
      <CardBtnPart {...cardBtnPartProps}>{partName}</CardBtnPart>
      {!!imageUrl && (
        <CardImage src={process.env.REACT_APP_IMG_URL + imageUrl} alt={title} />
      )}
      <Icon icon={icons.triangle} />
    </Card>
  );
};

export default Page;
