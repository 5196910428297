import React from "react";

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#9E9D9D" />
    <path
      d="M17 15.7817L13.1733 11.9921L16.9617 8.16958L15.7817 7L11.9942 10.8246L8.17083 7.03792L7 8.20875L10.8275 12.0025L7.03792 15.8292L8.20875 17L12.0054 13.17L15.8304 16.9617L17 15.7817Z"
      fill="#922D15"
    />
  </svg>
);
