import React from "react";

import { hydrate } from "react-dom";
import runStore from "./store";
import rootSaga from "./sagas";
import "./index.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./containers/App";

const state = window.__INITIAL__STATE__;
const store = runStore(state);
const root = document.getElementById("root");

store.runSaga(rootSaga);

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  root
);
