import * as types from "./actionTypes";

export const setChannel = (payload) => ({
  type: types.CHANNEL_SET,
  payload,
});

export const setIsChannel = (payload) => ({
  type: types.CHANNEL_IS,
  payload,
});

export const updateChannel = () => ({
  type: types.CHANNEL_UPDATE,
});

export const updateChannelSuccess = () => ({
  type: types.CHANNEL_UPDATE_SUCCESS,
});

export const resetChannel = () => ({
  type: types.CHANNEL_RESET,
});
