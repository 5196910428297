import React from "react";
import styled from "styled-components";

import Icon from "../Icon";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ btn_bgc }) => btn_bgc || "#edeee9"};
  border: 1px solid ${({ btn_bc }) => btn_bc || "#89949d"};
  border-radius: ${({ btn_br }) => btn_br || "20px"};
  padding: ${({ secondary }) => (secondary ? "4px 10px" : "6px 15px")};
  cursor: pointer;
  outline: none;
  margin: 0;

  height: ${({ btn_height }) => btn_height || "fit-content"};
  width: ${({ btn_width }) => btn_width || "fit-content"};

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  white-space: ${({ btn_wrap }) => btn_wrap || "nowrap"};
  color: ${({ btn_c }) => btn_c || "#24627a"};

  ${({ is_icon }) => is_icon && "svg {margin-right: 10px}"};

  @media (min-width: 769px) {
    font-size: ${({ secondary }) => (secondary ? "14" : "16")}px;
    line-height: ${({ secondary }) => (secondary ? "17" : "19")}px;
  }

  @media (max-width: 768px) {
    font-size: ${({ secondary }) => (secondary ? "12" : "16")}px;
    line-height: ${({ secondary }) => (secondary ? "14" : "19")}px;
  }
`;

export default function ({
  btn_txt,
  btn_conf,
  btn_bgc,
  btn_c,
  btn_bc,
  btn_br,
  btn_wrap,
  btn_width,
  btn_height,
  icon,
  icon_fill,
  icon_height,
  icon_width,
  secondary,
  onBtnClick,
  className,
}) {
  return (
    <Button
      className={className}
      as={(btn_conf && btn_conf.as) || null}
      download={(btn_conf && btn_conf.download) || null}
      disabled={(btn_conf && btn_conf.disabled) || null}
      to={(btn_conf && btn_conf.to) || null}
      href={(btn_conf && btn_conf.href) || null}
      target={(btn_conf && btn_conf.target) || null}
      rel={(btn_conf && btn_conf.rel) || null}
      style={(btn_conf && btn_conf.activeStyle) || null}
      btn_bgc={btn_bgc}
      btn_c={btn_c}
      btn_bc={btn_bc}
      btn_br={btn_br}
      btn_wrap={btn_wrap}
      btn_width={btn_width}
      btn_height={btn_height}
      secondary={secondary}
      is_icon={icon}
      onClick={onBtnClick || null}
    >
      {icon && (
        <Icon
          icon={icon}
          fill={icon_fill}
          width={icon_width}
          height={icon_height}
        />
      )}
      {btn_txt}
    </Button>
  );
}
