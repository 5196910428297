import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";
import styled from "styled-components";
import qs from "qs";

const NavParts = styled.div`
  display: flex;
  width: 100%;

  padding-top: 0;
  padding-bottom: 0;

  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  -ms-overflow-style: -ms-autohiding-scrollbar;

  ::-webkit-scrollbar {
    display: none;
  }

  a:not(:last-child) {
    margin-right: 10px;
  }

  @media (hover: hover) and (pointer: fine) {
    > a:hover {
      background: rgb(36, 98, 122);
      color: rgb(237, 238, 233);
    }
  }

  @media (max-width: 1090px) and (min-width: 769px) {
    a:first-child {
      margin-left: 30px;
    }
  }

  @media (min-width: 769px) {
    margin-top: 50px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;

    a:first-child {
      margin-left: 15px;
    }
  }
`;

export default () => {
  const { pathname, search } = useLocation();
  const [activeId, setActiveId] = useState(null);

  const isFilters = !!search;

  let { query, theme, type, part, year } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const str = qs.stringify({ query, theme, type, year });

  const parts = [
    { part_id: 1, name: "Методические материалы" },
    { part_id: 7, name: "Исследования" },
    { part_id: 2, name: "Публикации" },
    { part_id: 3, name: "События" },
    { part_id: 6, name: "Подкасты" },
    { part_id: 4, name: "Образовательные программы" },
  ];

  useEffect(() => {
    const isPartInPath = pathname.includes("parts");
    const isPartInSearch = !!part;
    const isPart = isPartInPath || isPartInSearch;

    if (isPart) {
      if (isPartInSearch) {
        part = +part;
      } else part = +pathname.split("/")[2];
    }

    setActiveId(part);
  }, [part, pathname]);

  const getUrlByIsActiveAndIsFilters = (partId, isActive) => {
    return isFilters
      ? `search?${str}${isActive ? "" : "&part=" + partId}`
      : `/${isActive ? "" : "parts/" + partId}`;
  };

  return (
    <NavParts>
      {parts.map(({ part_id, name }) => {
        const isActive = part_id === activeId;
        const url = getUrlByIsActiveAndIsFilters(part_id, isActive);

        const btn_conf = {
          to: url,
          as: Link,
          activeStyle: isActive
            ? { background: "#24627A", color: "#EDEEE9" }
            : null,
        };

        return <Button key={part_id} btn_txt={name} btn_conf={btn_conf} />;
      })}
    </NavParts>
  );
};
