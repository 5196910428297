const status = {
  dzen: "Аккаунт it-nekrasovka@yandex.ru добавлен в администраторы группы Дзен",
  odnoklassniki:
    "Аккаунт https://ok.ru/profile/583733460657 добавлен в модераторы сообщества в Одноклассниках",
  "curated-website":
    "Аккаунту monitoring@nekrasovka.ru предоставлен доступ к статистике сайта на Яндекс.Метрике",
  telegram: "Информация по Телеграм-каналу выгружается автоматически",
  rutube: "Сбор статистики с Rutube не подлежит автоматизации",
  vkontakte:
    "Аккаунт https://vk.com/id645177963 добавлен в администраторы группы/сообщества в VK",
  youtube:
    'Аккаунту monitoring.nekrasovka@gmail.com назначена роль "Пользователь с правами просмотра"',
};

const activation = {
  dzen: `<a target='_blank' href="${process.env.REACT_APP_URL}/help/Автоматизация+сбора+статистики+группы+в+Дзене.pdf">Автоматизация сбора статистики группы&nbsp;в&nbsp;Дзене</a>`,
  odnoklassniki: `<a target='_blank' href="${process.env.REACT_APP_URL}/help/Автоматизации+сбора+статистики+в+Одноклассниках.pdf">Автоматизации сбора статистики в&nbsp;Одноклассниках</a>`,
  "curated-website": `<a target='_blank' href="${process.env.REACT_APP_URL}/help/Автоматизация+сбора+статистики+с+сайта.pdf">Автоматизация сбора статистики с&nbsp;сайта</a>`,
  telegram:
    "Само добавление соцсети в наш список означает, что с него потом может быть выгружена статистика",
  rutube: "Сбор статистики с Rutube не подлежит автоматизации",
  vkontakte: `<a target='_blank' href="${process.env.REACT_APP_URL}/help/Автоматизация+сбора+статистики+группы+в+VK.pdf">Автоматизация сбора статистики группы&nbsp;в&nbsp;VK</a>`,
  youtube: `<a target='_blank' href="${process.env.REACT_APP_URL}/help/Автоматизация+сбора+статистики+Youtube_канала.pdf">Автоматизация сбора статистики Youtube-канала</a>`,
};

export { status, activation };
