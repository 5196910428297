import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

const ProgressBarContainer = styled.div`
  position: fixed;
  top: 0;
  transition: width 300ms linear;
  height: 5px;
  z-index: 20000;
  background-color: #ffad20;
  width: ${({ progress }) => progress}%;
`;

const ProgressBar = ({ progress }) =>
  progress ? <ProgressBarContainer progress={progress} /> : null;

const mapStateToProps = ({ progress: { progress } }) => ({
  progress,
});

export default connect(mapStateToProps)(ProgressBar);
