import * as types from "./actionTypes";

const initialState = {
  tokenParsed: {},
  roles: [],
  department: "",
  name: "",
  user: "",
  checkTokenExpiredYesUpdate: null,
  isAllowanceLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ALLOWANCE_GET:
      return {
        ...state,
        ...action.payload,
        isAllowanceLoading: true,
      };

    default:
      return state;
  }
};
