import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logos from "../Logos";
import Icon, { icons } from "../Icon";
import { themes, types, years } from "../../data";
import Section from "../../components/Section";
import objectToUrlConstructor from "../../helpers/objectToUrlConstructor";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { connect } from "react-redux";
import { resetFilters } from "../../reducers/filters/actions";

const ModalFilters = ({ isFiltersOpen, name, filter, resetFilters }) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [modalPaddingLeft, setModalPaddingLeft] = useState("");
  const [selected, setSelected] = useState({ year: [], theme: [], type: [] });

  const params = qs.parse(search, { ignoreQueryPrefix: true });
  const rootHeader = document.querySelector("#root header");
  const filters = { theme: themes, type: types, year: years };

  const isPartsInPath = pathname.includes("parts");
  const isPartInSearch = !!params.part;
  const isPart = isPartsInPath || isPartInSearch;

  if (isPart) {
    params.part = isPartInSearch ? +params.part : +pathname.split("/")[2];
  }

  const handleClose = () => resetFilters();

  const handleChangeFilter = () => {
    params[name] = selected[name];
    const url = objectToUrlConstructor(params);

    history.replace(`/search?${url}`);
    handleClose();
  };

  const handleSelectFilter = (item) => {
    setSelected({
      ...selected,
      [name]: selected[name].includes(item)
        ? selected[name].filter((f) => f !== item)
        : [...selected[name], item],
    });
  };

  useEffect(() => {
    if (isFiltersOpen && !!params[name] && params[name] !== selected[name]) {
      setSelected({ ...selected, [name]: params[name].split(",") });
    }

    return () => setSelected({ year: [], theme: [], type: [] });
  }, [isFiltersOpen]);

  useEffect(() => {
    if (rootHeader && !modalPaddingLeft) {
      const styles = window.getComputedStyle(rootHeader);
      setModalPaddingLeft(styles.marginLeft);
    }
  }, [rootHeader, modalPaddingLeft]);

  return (
    <MenuContainer
      isFiltersOpen={isFiltersOpen}
      modalPaddingLeft={modalPaddingLeft}
    >
      <ContainerHeader>
        <Logos />
        <HeaderCloseIcon onClick={handleClose}>
          <Icon icon={icons.close_x} />
        </HeaderCloseIcon>
      </ContainerHeader>
      <FilterName>{filter}</FilterName>
      <ContainerMain>
        {name && (
          <ContainerMainButtons>
            {Object.keys(filters[name]).map((item) => {
              const btn = filters[name];
              const active = selected[name].includes(item);
              const text = btn[item] || item;

              return (
                <ModalBtn
                  as="button"
                  key={item}
                  btnColor={active ? "#EDEEE9" : "#9E9D9D"}
                  btnBackgroundColor={active ? "#24627A" : "initial"}
                  onClick={() => handleSelectFilter(item)}
                >
                  {text}
                </ModalBtn>
              );
            })}
          </ContainerMainButtons>
        )}
        <ModalSubmitBtn as="button" onClick={handleChangeFilter}>
          Применить
        </ModalSubmitBtn>
      </ContainerMain>
    </MenuContainer>
  );
};

const mapStateToProps = ({ filters: { name, filter, isFiltersOpen } }) => ({
  name,
  filter,
  isFiltersOpen,
});

export default connect(mapStateToProps, {
  resetFilters,
})(ModalFilters);

const MenuContainer = styled.div`
  display: block;
  height: 100vh;

  background: #ffffff;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12000;

  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background: inherit;
  }

  transform: translateX(
    ${({ isFiltersOpen }) => (isFiltersOpen ? "0" : "-100%")}
  );
  transition: transform linear 0.2s;

  @media (min-width: 569px) {
    width: 50%;
    border-right: 3px solid #24627a;
    padding-left: ${({ modalPaddingLeft }) => modalPaddingLeft};
  }

  @media (max-width: 568px) {
    width: 100%;
    border-top: 3px solid #24627a;
  }
`;

const ContainerMain = styled(Section)`
  margin-top: 25px;

  @media (min-width: 569px) {
    height: auto;
  }

  @media (max-width: 568px) {
    height: calc(100% + 60px);
  }
`;

const ContainerMainButtons = styled.div`
  @media (min-width: 569px) {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
`;

const FilterName = styled(Section)`
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  color: #24627a;

  @media (min-width: 569px) {
    margin-top: 87px;
  }

  @media (max-width: 569px) {
    margin-top: 25px;
  }
`;

const ContainerHeader = styled.div`
  @media (min-width: 769px) {
    padding-left: 30px;
    height: 91px;
  }

  @media (max-width: 768px) {
    padding-left: 15px;
    height: 60px;
  }

  @media (min-width: 569px) {
    display: flex;
    position: sticky;
    background: #ffffff;

    top: 0;
    z-index: 1111;
  }

  @media (max-width: 568px) {
    display: none;
  }
`;

const HeaderCloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;

  svg {
    path {
      fill: #222222;
    }
  }

  @media (min-width: 769px) {
    svg {
      width: 30px;
      height: 30px;
      margin-right: 40px;
    }
  }

  @media (max-width: 768px) {
    svg {
      width: 22px;
      height: 22px;
      margin-right: 15px;
    }
  }
`;

const CardButton = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;

  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
`;

const ModalBtn = styled(CardButton)`
  border: 1px solid #9e9d9d;
  color: ${({ btnColor }) => btnColor};
  background-color: ${({ btnBackgroundColor }) => btnBackgroundColor};
  padding: 4px 10px;
  margin-bottom: 10px;
  border-radius: 20px;

  @media (min-width: 769px) {
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: 569px) {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  @media (max-width: 568px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    justify-content: center;

    :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: #24627a;
      border-color: #24627a;
      color: #edeee9;
    }
  }
`;

const ModalSubmitBtn = styled(CardButton)`
  justify-content: center;
  align-items: center;
  color: #edeee9;
  background-color: #24627a;
  height: 50px;
  width: 178px;
  border-radius: 35px;

  font-size: 18px;
  line-height: 21px;

  @media (min-width: 569px) {
    margin-top: 30px;
  }

  @media (max-width: 568px) {
    height: 45px;
    width: 100%;
    margin-top: 25px;
  }
`;
